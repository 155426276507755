import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import { INationality } from "../nationalityModel";

export const getAllNationalities = createAsyncThunk(
  "Nationality/FindAllNationality",
  async () => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Nationality/FindAllNationality`,
        {
          NationalityName: "",
        }
      );
      return response.data?.Data;
    } catch (error: any) {
      console.error("Error Fetching Nationalities:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createNationality = createAsyncThunk(
  "Nationality/InsertNationality",
  async (NationalityData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Nationality/InsertNationality`,
        NationalityData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Nationalities:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateNationality = createAsyncThunk(
  "Nationality/UpdateNationality",
  async (NationalityData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Nationality/UpdateNationality`,
        NationalityData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating Nationality:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getNationalityById = createAsyncThunk(
  "Nationality/FindByIDNationality",
  async (ID: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Nationality/FindByIDNationality`,
        { nationality_guid: ID }
      );
      // SuccessToast(response.data?.Details || "Success")
      const result = response.data?.Data as any;
      return {
        id: result.id,
        nationality_name: result.nationality_name,
        isactive: result.isactive === true ? 1 : 2,
      } as INationality;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Nationalities:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteNationality = createAsyncThunk(
  "Nationality/DeleteNationality",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Nationality/DeleteNationality`,
        { nationality_guid: ID }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Deleting Nationality:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const findAllActiveNationality = createAsyncThunk(
  "Nationality/FindAllActiveNationality",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/Nationality/FindAllActiveNationality`
      );
      // SuccessToast(response.data?.Details || "Success");
      return response.data.Data;
    } catch (error: any) {
      console.error("Error Deleting nationalities:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const NationalityActiveInactive = createAsyncThunk(
  "Nationality/ActiveInActiveNationality",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Nationality/ActiveInActiveNationality`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error active-inactive nationality:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
