import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IItinerary, IItineraryInitialState } from "./itineraryModel";
import {
  getAllItinerary,
  createItinerary,
  updateItinerary,
  deleteItinerary,
  getItineraryByID,
  getAllActiveItinerary,
  ActiveInactiveItinerary,
  getItineraryBySectorID,
  // getItineraryBySectorID,
} from "./services/itinerary.services";

const initialState: IItineraryInitialState = {
  loading: false,
  formLoading: false,
  error: "",
  ItineraryList: [],
  ItineraryID: null,
  ItineraryGUID: "",
  ItineraryDetail: {
    id: null,
    sector_id: null,
    itinerary_title: "",
    description: "",
    isactive: 1,
  },
};

const itinerarySlice = createSlice({
  name: "itinerary",
  initialState,
  reducers: {
    clearItineraryDetails: (state) => {
      state.ItineraryDetail = initialState.ItineraryDetail;
    },
    setItineraryID: (state, action) => {
      state.ItineraryID = action.payload;
    },
    clearItineraryID: (state) => {
      state.ItineraryID = initialState.ItineraryID;
    },
    setItineraryGUID: (state, action) => {
      state.ItineraryGUID = action.payload;
    },
    clearItineraryGUID: (state) => {
      state.ItineraryGUID = initialState.ItineraryGUID;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllItinerary.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllItinerary.fulfilled,
      (state, action: PayloadAction<IItinerary[]>) => {
        state.loading = false;
        state.ItineraryList = action.payload || [];
      }
    );
    builder.addCase(getAllItinerary.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.ItineraryList = [];
    });
    builder.addCase(getItineraryBySectorID.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getItineraryBySectorID.fulfilled,
      (state, action: PayloadAction<IItinerary[]>) => {
        state.loading = false;
        state.ItineraryList = action.payload || [];
      }
    );
    builder.addCase(getItineraryBySectorID.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.ItineraryList = [];
    });
    builder.addCase(createItinerary.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(createItinerary.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(createItinerary.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(updateItinerary.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(updateItinerary.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(updateItinerary.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(deleteItinerary.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteItinerary.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteItinerary.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getItineraryByID.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(getItineraryByID.fulfilled, (state, action: any) => {
      state.formLoading = false;
      state.ItineraryDetail = action.payload;
    });
    builder.addCase(getItineraryByID.rejected, (state, action) => {
      state.formLoading = false;
      state.ItineraryDetail = initialState.ItineraryDetail;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getAllActiveItinerary.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllActiveItinerary.fulfilled,
      (state, action: PayloadAction<IItinerary[]>) => {
        state.loading = false;
        state.ItineraryList = action.payload || [];
      }
    );
    builder.addCase(getAllActiveItinerary.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.ItineraryList = [];
    });
    builder.addCase(ActiveInactiveItinerary.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(ActiveInactiveItinerary.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(ActiveInactiveItinerary.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const {
  clearItineraryDetails,
  clearItineraryID,
  setItineraryID,
  setItineraryGUID,
  clearItineraryGUID,
} = itinerarySlice.actions;
export default itinerarySlice.reducer;
