import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import { IFacility } from "../facilityModel";

export const getAllFacilitys = createAsyncThunk(
  "Facility/FindAllFacility",
  async () => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Facility/FindAllFacility`,
        {
          name: "",
        }
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Room Types:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createFacility = createAsyncThunk(
  "Facility/InsertFacility",
  async (FacilityData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Facility/InsertFacility`,
        FacilityData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating facility:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateFacility = createAsyncThunk(
  "Facility/UpdateFacility",
  async (FacilityData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Facility/UpdateFacility`,
        FacilityData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating facility:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteFacility = createAsyncThunk(
  "Facility/DeleteFacility",
  async (GUID: string) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Facility/DeleteFacility`,
        { facility_guid: GUID }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting facility:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getFacilityByID = createAsyncThunk(
  "Facility/FindByIDFacility",
  async (GUID: string) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Facility/FindByIDFacility`,
        { facility_guid: GUID }
      );
      const result = response.data?.Data as any;
      return {
        facility_guid: result.facility_guid,
        name: result.name,
        isactive: result.isactive ? 1 : 2,
      } as IFacility;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching facility:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAllActiveFacilitys = createAsyncThunk(
  "Facility/FindAllActiveFacility",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/Facility/FindAllActiveFacility`
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching facilitys:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const ActiveInactiveFacility = createAsyncThunk(
  "Facility/ActiveInActiveFacility",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Facility/ActiveInActiveFacility`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error active-inactive facility:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
