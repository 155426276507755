import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";

export const getAllMenus = createAsyncThunk("Menu/FindAllMenu", async () => {
  try {
    const response = await axiosApiInstance.post(
      `${API_URL}/Menu/FindAllMenu`,
      {
        menu_name: "",
      }
    );
    return response.data?.Data;
  } catch (error: any) {
    // ErrorToast(error?.response?.data?.Details || "Something went wrong");
    console.error("Error Fetching Menus:", error);
    return Promise.reject({
      message: error?.response?.data?.Details || "Something went wrong",
    });
  }
});

export const createMenu = createAsyncThunk(
  "Menu/InsertMenu",
  async (MenuData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Menu/InsertMenu`,
        MenuData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating menu:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateMenu = createAsyncThunk(
  "Menu/UpdateMenu",
  async (MenuData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Menu/UpdateMenu`,
        MenuData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating menu:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteMenu = createAsyncThunk(
  "Menu/DeleteMenu",
  async (ID: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Menu/DeleteMenu`,
        { menu_guid: ID }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting menu:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getMenuByID = createAsyncThunk(
  "Menu/FindByIDMenu",
  async (ID: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Menu/FindByIDMenu`,
        { menu_guid: ID }
      );
      const result = response.data?.Data as any;
      return {
        id: result.id,
        menu_guid: result.menu_guid,
        menu_name: result.menu_name,
        menu_key: result.menu_key,
        is_menu: result.is_menu,
        under_menu_id: result.under_menu_id,
        isactive: result.isactive === true ? 1 : 2,
      } as any;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching menu:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAllActiveMenus = createAsyncThunk(
  "Menu/FindAllActiveMenu",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/Menu/FindAllActiveMenu`
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Menus:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const ActiveInactiveMenu = createAsyncThunk(
  "Menu/ActiveInActiveMenu",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Menu/ActiveInActiveMenu`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error active-inactive menu:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAllActiveMenuSubMenuList = createAsyncThunk(
  "Menu/FindAllActiveMenuListSub",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/Menu/FindAllActiveMenuListSub`
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Menus:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
