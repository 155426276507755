import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosApiInstance } from "../../../app/axios";
import { API_URL } from "../../../app/config";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import { ITravelerInfo, IUserMDetails } from "../userManagementModel";
import moment from "moment";

export const getAllUsersM = createAsyncThunk(
  "admin/FindAllUser",
  async (payload: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/admin/FindAllUser`,
        payload
      );
      return response.data.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching users:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateUserM = createAsyncThunk(
  "admin/UpdateUserTravellers",
  async (UserData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/admin/UpdateUserTravellers`,
        UserData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating user:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getUserMById = createAsyncThunk(
  "admin/FindByIdUserTravellerBilling",
  async (ID: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/admin/FindByIdUserTravellerBilling`,
        { user_guid: ID }
      );
      // SuccessToast(response.data?.Details || "Success")
      const result = response.data?.Data;
      return {
        Id: result.Id,
        user_guid: result.user_guid,
        first_name: result.first_name,
        last_name: result.last_name,
        date_of_birth: result.date_of_birth
          ? moment(result.date_of_birth, "DD/MM/YYYY").toDate()
          : "",
        gender: result.gender,
        nationality_id: result.nationality_id,
        nationality_name: result.nationality_name,
        city_id: result.city_id,
        city_name: result.city_name,
        profile_photo: result.profile_photo,
        is_email_verified: result.is_email_verified,
        billing_address: result.billing_address,
        billing_state_id: result.billing_state_id,
        billing_state_name: result.billing_state_name,
        billing_pincode: result.billing_pincode,
        billing_city_id: result.billing_city_id,
        billing_city_name: result.billing_city_name,
        billing_pan_card_no: result.billing_pan_card_no,
        billing_gst_no: result.billing_gst_no,
        travellers_information: result.travellers_information?.map(
          (tInfo: any) => {
            return {
              id: tInfo.id,
              traveller_information_guid: tInfo.traveller_information_guid,
              user_id: tInfo.user_id,
              first_name: tInfo.first_name,
              last_name: tInfo.last_name,
              age_group: tInfo.age_group,
              date_of_birth: tInfo.date_of_birth
                ? moment(tInfo.date_of_birth, "YYYY-MM-DD").toDate()
                : "",
              initial: tInfo.initial,
              gender: tInfo.gender,
              mobile_no: tInfo.mobile_no,
              email_id: tInfo.email_id,
              pan_card_no: tInfo.pan_card_no,
              passport_no: tInfo.passport_no,
              expire_date: tInfo.expire_date
                ? moment(tInfo.date_of_birth, "YYYY-MM-DD").toDate()
                : "",
              issue_country: tInfo.issue_country,
              issue_country_name: tInfo.issue_country_name,
              country_code: tInfo.country_code,
            } as ITravelerInfo;
          }
        ),
        isactive: result.isactive === true ? 1 : 2,
      } as IUserMDetails;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching states:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteTravallerInfo = createAsyncThunk(
  "TravellersInformation/DeleteTravellersInformation",
  async (ID: string) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/TravellersInformation/DeleteTravellersInformation`,
        { traveller_information_guid: ID }
      )
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Delete Traveller:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
