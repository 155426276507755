import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { IAbandonedFlightBookingDetails } from "../abandonedFlightBookingModel";
import { ErrorToast } from "../../../components/toast/Toasts";

export const findAllActiveAbandonedFlightBooking = createAsyncThunk(
  "Admin/FindAllActiveTempFlightBooking",
  async (formdata: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Admin/FindAllActiveTempFlightBooking`,
        formdata
      );
      // SuccessToast(response.data?.Details || "Success");
      return response.data.Data;
    } catch (error: any) {
      console.error("Error Deleting Flight Booking:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAbandonedFlightBookingByBookingId = createAsyncThunk(
  "Admin/FlightBookingDetailsByBookingNo",
  async (booking_no: string) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Admin/FlightBookingDetailsByBookingNo`,
        { booking_no }
      );
      // SuccessToast(response.data?.Details || "Success")
      const result = response.data?.Data as any;
      return {
        user_guid: result.user_guid,
        booking_no: result.booking_no,
        booking_date: result.booking_date,
        amount: result.amount,
        email_id: result.email_id,
        mobile_no: result.mobile_no,
        booking_status: result.booking_status,
        gst_number: result.gst_number,
        gst_email: result.gst_email,
        gst_registred_name: result.gst_registred_name,
        gst_mobile_no: result.gst_mobile_no,
        gst_address: result.gst_address,
        base_fare: result.base_fare,
        airline_gst: result.airline_gst,
        total_airline_tax: result.total_airline_tax,
        other_taxes: result.other_taxes,
        yr: result.yr,
        oc: result.oc,
        net_price: result.net_price,
        fare_rules: result.fare_rules,
        device_type_booking: result.device_type_booking,
        from_flight_place: result.from_flight_place,
        to_flight_place: result.to_flight_place,

        payment_id: result.payment_id, //Payments Key Start with here
        Entity: result.Entity,
        Currency: result.Currency,
        PaymentStatus: result.PaymentStatus,
        OrderId: result.OrderId,
        InvoiceId: result.InvoiceId,
        International: result.International,
        PaymentAmount: result.PaymentAmount,
        Method: result.Method,
        AmountRefunded: result.AmountRefunded,
        RefundStatus: result.RefundStatus,
        Captured: result.Captured,
        Description: result.Description,
        CardId: result.CardId,
        Bank: result.Bank,
        Wallet: result.Wallet,
        Vpa: result.Vpa,
        Email: result.Email,
        Contact: result.Contact,
        Fee: result.Fee,
        Tax: result.Tax,
        customer_id: result.customer_id,
        CreatedAt: result.CreatedAt,

        flight_details: result.flight_details
          ? result.flight_details?.map((flight: any) => {
              return {
                airline_name: flight.airline_name,
                flight_no: flight.flight_no,
                flight_class: flight.flight_class,
                flight_type: flight.flight_type,
                departure_date_time: flight.departure_date_time,
                departure_place: flight.departure_place,
                departure_airport: flight.departure_airport,
                departure_terminal: flight.departure_terminal,
                arrival_date_time: flight.arrival_date_time,
                arrival_place: flight.arrival_place,
                arrival_airport: flight.arrival_airport,
                arrival_terminal: flight.arrival_terminal,
                duration: flight.duration,
                baggage_information: flight.baggage_information,
                stop_details: flight.stop_details,
              };
            })
          : [],
        passenger_details: result.passenger_details
          ? result.passenger_details?.map((passenger: any) => {
              return {
                initial: passenger.initial,
                first_name: passenger.first_name,
                last_name: passenger.last_name,
                gender: passenger.gender,
                pax_type: passenger.pax_type,
                date_of_birth: passenger.date_of_birth,
                passport_no: passenger.passport_no,
                nationality_id: passenger.nationality_id,
                nationality_name: passenger.nationality_name,
                expiry_date: passenger.expiry_date,
                status: passenger.status,
                email_id: passenger.email_id,
                mobile_no: passenger.mobile_no,
                issue_country: passenger.issue_country,
              };
            })
          : [],
      } as IAbandonedFlightBookingDetails;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching FlightPlaces:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

