import { Field, FieldArrayRenderProps } from "@progress/kendo-react-form";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import React from "react";
import {
  closeDialog,
  openDialog,
} from "../../../components/dialog/dialogSlice";
import { setDeleteIndex } from "../tourSlice";
import { Card, GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
import AppDialog, {
  DialogCloseButton,
} from "../../../components/dialog/Dialog";
import { Typography } from "@progress/kendo-react-common";
import IconButton from "../../../components/common/IconButton";
import { MdDelete } from "react-icons/md";
import FormDatePicker from "../../../components/formFields/FormDateField";
import { requiredValidator } from "../../../components/formFields/CommonValidator";
import FormTextField from "../../../components/formFields/FormTextField";
import moment from "moment";
// import { useLocation } from "react-router-dom";

import { ErrorToast } from "../../../components/toast/Toasts";
import { deleteTourDate } from "../services/tour.services";

const TourDateArray = (fieldArrayRenderProps: FieldArrayRenderProps) => {
  const dispatch = useAppDispatch();
  const TourDateDeleteIndex = useAppSelector((state) => state.tour.deleteIndex);
  const dialogName = useAppSelector((state) => state.dialog.dialogName);
  // const location = useLocation();
  // const TourCode = location.state?.TourCode;

  const handleOpenDeleteDialog = (index: number) => {
    dispatch(openDialog("tourDateDeleteDialog"));
    dispatch(setDeleteIndex(index));
  };

  const handleDeleteAction = React.useCallback(
    async (index: number) => {
      if (fieldArrayRenderProps?.value[index]?.tour_date_guid) {
        try {
          const response = await dispatch(
            deleteTourDate(fieldArrayRenderProps?.value[index]?.tour_date_guid)
          );

          console.log("response0", response);

          if (response.payload?.Status === 200) {
            fieldArrayRenderProps.onRemove({ index: index });
            dispatch(closeDialog());
            dispatch(setDeleteIndex(-1));
          }
        } catch (error: any) {
          ErrorToast(error?.response?.data?.Details || "Something went wrong");
          console.error("Error deleting tourdate:", error);
          return Promise.reject({
            message: error?.response?.data?.Details || "Something went wrong",
          });
        }
      } else {
        fieldArrayRenderProps.onRemove({ index: index });
        dispatch(closeDialog());
        dispatch(setDeleteIndex(-1));
      }
    },
    [fieldArrayRenderProps]
  );

  const pushElementInarray = React.useCallback(() => {
    fieldArrayRenderProps.onPush({
      value: {
        tour_date: "",
        tour_date_code: "",
      },
    });
  }, [fieldArrayRenderProps]);

  const handleTourDateChange = (e: any, index: number) => {
    const TourDateCode = moment(e.value).format("DDMMMYYYY");
    fieldArrayRenderProps?.formRenderProps?.onChange(
      `TourDates.${index}.tour_date_code`,
      {
        value: TourDateCode?.toUpperCase(),
      }
    );
  };

  return (
    <GridLayout cols={[{ width: "100%" }]}>
      {!fieldArrayRenderProps.value ? pushElementInarray() : null}

      {dialogName === "tourDateDeleteDialog" && (
        <AppDialog>
          <>
            <Typography.h5>{"Delete Tour Date"}</Typography.h5>
            <GridLayout>
              <GridLayoutItem>
                <Typography.p>
                  Are you sure you want to delete Tour Date this?
                </Typography.p>
              </GridLayoutItem>
              <GridLayoutItem
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <DialogCloseButton themeColor="error" />
                <Button
                  fillMode={"solid"}
                  themeColor={"error"}
                  onClick={() => handleDeleteAction(TourDateDeleteIndex)}
                  type="button"
                >
                  Delete
                </Button>
              </GridLayoutItem>
            </GridLayout>
          </>
        </AppDialog>
      )}
      {fieldArrayRenderProps.value &&
        fieldArrayRenderProps.value.length > 0 &&
        fieldArrayRenderProps.value.map((TourDate: any, index: number) => (
          <GridLayoutItem key={index}>
            <Card className="fieldarray-card">
              <GridLayout
                style={{ marginRight: 40 }}
                gap={{ rows: 0, cols: 10 }}
                cols={[{ width: "45%" }, { width: "45%" }, { width: "10%" }]}
              >
                <GridLayoutItem>
                  <Field
                    name={`TourDates.${index}.tour_date`}
                    label="Tour Date"
                    onChange={(e) => handleTourDateChange(e, index)}
                    format="dd/MM/yyyy"
                    component={FormDatePicker}
                    validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name={`TourDates.${index}.tour_date_code`}
                    label="Tour Code"
                    validator={requiredValidator}
                    component={FormTextField}
                  />
                </GridLayoutItem>

                <GridLayoutItem
                  style={{ display: "flex", width: "100%", gap: 10 }}
                >
                  <IconButton
                    onClick={() => handleOpenDeleteDialog(index)}
                    disabled={fieldArrayRenderProps.value.length === 1}
                    themeColor={"error"}
                    size={"small"}
                    fillMode={"solid"}
                    type="button"
                    style={{ height: 24, width: 24, marginTop: 38 }}
                  >
                    <MdDelete
                      className="absolute-position"
                      style={{ fontSize: "16px" }}
                    />
                  </IconButton>
                </GridLayoutItem>
              </GridLayout>
            </Card>
          </GridLayoutItem>
        ))}
      <GridLayoutItem style={{ marginTop: 10 }}>
        <Button
          fillMode={"solid"}
          themeColor={"primary"}
          onClick={pushElementInarray}
          type="button"
        >
          Add Tour Date
        </Button>
      </GridLayoutItem>
    </GridLayout>
  );
};

export default TourDateArray;
