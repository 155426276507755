import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import { IDestination } from "../destinationModel";

export const getAllDestinations = createAsyncThunk(
  "Destination/FindAllDestination",
  async () => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Destination/FindAllDestination`,
        {
          destination: "",
        }
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Room Types:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createDestination = createAsyncThunk(
  "Destination/InsertDestination",
  async (DestinationData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Destination/InsertDestination`,
        DestinationData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating destination:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateDestination = createAsyncThunk(
  "Destination/UpdateDestination",
  async (DestinationData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Destination/UpdateDestination`,
        DestinationData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating destination:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteDestination = createAsyncThunk(
  "Destination/DeleteDestination",
  async (GUID: string) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Destination/DeleteDestination`,
        { destination_guid: GUID }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting destination:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getDestinationByID = createAsyncThunk(
  "Destination/FindByIDDestination",
  async (GUID: string) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Destination/FindByIDDestination`,
        { destination_guid: GUID }
      );
      const result = response.data?.Data as any;
      return {
        destination_guid: result?.destination_guid,
        destination: result?.destination,
        tour_type: result?.tour_type
          ? result?.tour_type === "International"
            ? 1
            : 2
          : 1,
        description: result?.description,
        meta_title: result?.meta_title,
        meta_description: result?.meta_description,
        meta_keywords: result?.meta_keywords,
        destination_image: result?.destination_image,
        banner_image: result?.banner_image,
        is_populer: result?.is_populer ? 1 : 2,
        isactive: result?.isactive ? 1 : 2,
      } as IDestination;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching destination:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAllActiveDestinations = createAsyncThunk(
  "Destination/FindAllActiveDestination",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/Destination/FindAllActiveDestination`
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching destinations:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const ActiveInactiveDestination = createAsyncThunk(
  "Destination/ActiveInActiveDestination",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Destination/ActiveInActiveDestination`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error active-inactive destination:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const DestinationImageUpload = createAsyncThunk(
  "Destination/DestinationImageUpload",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Destination/DestinationImageUpload`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Upload documnets:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
