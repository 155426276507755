import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Typography } from "@progress/kendo-react-common";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import FormSelectionField from "../../../components/formFields/FormSelectionField";
import { requiredValidator } from "../../../components/formFields/CommonValidator";
import FormTextField from "../../../components/formFields/FormTextField";
import { STATUSARRAY, TRAVEL_TYPE } from "../../../_contstants/common";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import ButtonWithLoading from "../../../components/common/ButtonWithLoading";
import {
  createTour,
  getTourByID,
  updateTour,
  uploadTourImages,
} from "../services/tour.services";
import { increaseActiveStep } from "../tourSlice";
import ShadowCard from "../../../components/common/ShadowCard";
import { IDestination } from "../../destination/destinationModel";
import FormRichTextField from "../../../components/formFields/FormRichTextField";
import FormDatePicker from "../../../components/formFields/FormDateField";
import FormMultiSelectionFiled from "../../../components/formFields/FormMultiSelectionFiled";
import { IFacility } from "../../facility/facilityModel";
import { ISpecialityType } from "../../specialitytype/specialityTypeModel";
import FormCheckbox from "../../../components/formFields/FormCheckbox";
import { Button } from "@progress/kendo-react-buttons";
import { getAllActiveDestinations } from "../../destination/services/destination.services";
import { getAllActiveSpecialityTypes } from "../../specialitytype/services/specialityType.services";
import { getAllActiveFacilitys } from "../../facility/services/facility.services";
import FormImageUpload from "../../../components/formFields/FormImageUpload";
import FormTextArea from "../../../components/formFields/FormTextArea";
import { LoadingPanel } from "../../../components/layout/Loading";
import moment from "moment";
import { SuccessToast } from "../../../components/toast/Toasts";

const TourInformation: React.FC = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const gridRef = useRef<any>(null);
  const TourID = location.state?.TourID;
  const TourGUID = location.state?.TourGUID;
  // console.log("TourID", TourID);
  const formloading = useAppSelector((state) => state.tour.formloading);
  const TourDetail = useAppSelector((state) => state.tour.TourDetail);
  const FacilityList = useAppSelector((state) => state.facility.FacilityList);
  const SpecialityTypeList = useAppSelector(
    (state) => state.specialityType.SpecialityTypeList
  );
  const DestinationList = useAppSelector(
    (state) => state.destination.DestinationList
  );

  const [formKey, setFormKey] = useState(1);

  useEffect(() => {
    dispatch(getAllActiveDestinations());
    dispatch(getAllActiveSpecialityTypes());
    dispatch(getAllActiveFacilitys());
  }, []);

  useEffect(() => {
    if (TourGUID) {
      dispatch(getTourByID(TourGUID));
    }
  }, [TourGUID]);

  useEffect(() => {
    setFormKey(formKey + 1);
  }, [TourDetail]);

  const handleNoOfNightsChange = (e: any, formRenderProps: FormRenderProps) => {
    formRenderProps?.onChange(`no_of_days`, {
      value: +e.value + 1 || null,
    });
  };

  const handleSubmit = async (values: any) => {
    const formdata = {
      sector_id: values.sector_id,
      travel_type: values.travel_type,
      tour_name: values.tour_name,
      speciality_type_id: Array.isArray(values?.speciality_type_id)
        ? values?.speciality_type_id.join(",")
        : values?.speciality_type_id ?? "",
      no_of_nights: values.no_of_nights,
      no_of_days: values.no_of_days,
      short_description: values.short_description,
      long_description: values.long_description,
      meta_title: values.meta_title,
      meta_description: values.meta_description,
      meta_keywords: values.meta_keywords,
      tour_from_date: values.tour_from_date
        ? moment(values.tour_from_date).format("YYYY-MM-DD")
        : null,
      tour_to_date: values.tour_to_date
        ? moment(values.tour_to_date).format("YYYY-MM-DD")
        : null,
      facility_id: Array.isArray(values?.facility_id)
        ? values?.facility_id.join(",")
        : values?.facility_id ?? "",
      is_show_home: values.is_show_home,
      is_populer_tour: values.is_populer_tour,
      isactive: values?.isactive === 1,
    };
    try {
      if (!TourID) {
        const response = await dispatch(createTour(formdata));
        // console.log("response", response);
        if (
          response?.payload?.Status === 201 &&
          response.payload?.Data?.tour_guid &&
          response.payload?.Data?.id
        ) {
          const files = new FormData();
          files.append("tour_id", response.payload?.Data?.id);
          if (values?.other_image) {
            values?.other_image?.forEach((img: any) => {
              const otherImage = img.getRawFile();
              files.append("other_image", otherImage);
            });
          }
          if (values?.main_image) {
            const mainImage = values?.main_image[0].getRawFile();
            files.append("main_image", mainImage);
          }
          const res = await dispatch(uploadTourImages(files));
          console.log("res", res);
          if (res.payload?.Data?.Status === 200) {
            SuccessToast(res.payload?.Data?.Details || "Image Upload Success!");

            console.log("TourGUID", response.payload?.Data?.tour_guid);
            console.log("TourID", response.payload?.Data?.id);
            console.log("SectorID", values.sector_id);

            navigate("/tour/edit", {
              state: {
                TourGUID: response.payload?.Data?.tour_guid,
                TourID: response.payload?.Data?.id,
                SectorID: values.sector_id ? `${values.sector_id}` : "",
              },
            });
            dispatch(increaseActiveStep());
          }
        }
      } else {
        const editPayload = {
          tour_guid: values.tour_guid,
          ...formdata,
        };
        const response = await dispatch(updateTour(editPayload));
        console.log("response", response);
        if (response?.meta?.requestStatus === "fulfilled") {
          navigate("/tour/edit", {
            state: {
              TourID: TourID,
              TourGUID: TourGUID,
              SectorID: values.sector_id ? `${values.sector_id}` : "",
            },
          });
          dispatch(increaseActiveStep());
        }
      }
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      throw error;
    }
  };

  if (formloading) return <LoadingPanel gridRef={gridRef} />;
  return (
    <Form
      onSubmit={handleSubmit}
      key={formKey}
      initialValues={TourDetail}
      render={(formRenderProps: FormRenderProps) => (
        <FormElement>
          {/* <HotelListChangeWatcher formRenderProps={formRenderProps} /> */}
          <ShadowCard style={{ padding: 10 }}>
            <GridLayout
              style={{ marginRight: 20 }}
              gap={{ rows: 0, cols: 10 }}
              cols={[
                { width: "33.33%" },
                { width: "33.33%" },
                { width: "33.33%" },
              ]}
            >
              <GridLayoutItem colSpan={3}>
                <Typography.h4>{"Tour Details"}</Typography.h4>
              </GridLayoutItem>
              <GridLayoutItem style={{ width: "100%" }}>
                <Field
                  id={"sector_id"}
                  name={"sector_id"}
                  label={"Destination"}
                  component={FormSelectionField}
                  validator={requiredValidator}
                  options={DestinationList?.map((destination: IDestination) => {
                    return {
                      value: destination?.id,
                      label: destination?.destination,
                    };
                  })}
                />
              </GridLayoutItem>
              <GridLayoutItem style={{ width: "100%" }}>
                <Field
                  id={"travel_type"}
                  name={"travel_type"}
                  label={"Travel type"}
                  component={FormSelectionField}
                  validator={requiredValidator}
                  options={TRAVEL_TYPE?.map((traveltype: any) => {
                    return {
                      value: traveltype?.value,
                      label: traveltype?.label,
                    };
                  })}
                />
              </GridLayoutItem>
              <GridLayoutItem style={{ width: "100%" }}>
                <Field
                  name="tour_name"
                  label="Tour Name"
                  component={FormTextField}
                  validator={requiredValidator}
                />
              </GridLayoutItem>
              <GridLayoutItem
                style={{ width: "100%", display: "flex", gap: 10 }}
              >
                <Field
                  wrapperClassName="w-100"
                  name="no_of_nights"
                  label="No Of Nights"
                  type="number"
                  max="2"
                  onChange={(e) => handleNoOfNightsChange(e, formRenderProps)}
                  component={FormTextField}
                  validator={requiredValidator}
                />
                <Field
                  wrapperClassName="w-100"
                  name="no_of_days"
                  label="No Of Days"
                  type="number"
                  // max="2"
                  disabled={true}
                  component={FormTextField}
                  validator={requiredValidator}
                />
              </GridLayoutItem>

              <GridLayoutItem
                style={{ width: "100%", display: "flex", gap: 10 }}
              >
                <Field
                  wrapperClassName="w-100"
                  name="tour_from_date"
                  label="From Date"
                  minDate={moment().toDate()}
                  component={FormDatePicker}
                  validator={requiredValidator}
                />
                <Field
                  wrapperClassName="w-100"
                  name="tour_to_date"
                  label="To Date"
                  minDate={moment(
                    formRenderProps.valueGetter("tour_from_date")
                  ).toDate()}
                  component={FormDatePicker}
                  validator={requiredValidator}
                />
              </GridLayoutItem>
              <GridLayoutItem style={{ width: "100%" }}>
                <Field
                  id={"facility_id"}
                  name={"facility_id"}
                  label={"Facility"}
                  component={FormMultiSelectionFiled}
                  validator={requiredValidator}
                  options={FacilityList?.map((facility: IFacility) => {
                    return {
                      value: facility?.id,
                      label: facility?.name,
                    };
                  })}
                />
              </GridLayoutItem>

              <GridLayoutItem style={{ width: "100%" }}>
                <Field
                  name="meta_title"
                  label="Meta Title"
                  component={FormTextField}
                  validator={requiredValidator}
                />
              </GridLayoutItem>
              <GridLayoutItem style={{ width: "100%" }}>
                <Field
                  name="meta_keywords"
                  label="Meta Keywords"
                  component={FormTextArea}
                  rows={2}
                  resize={false}
                  validator={requiredValidator}
                />
              </GridLayoutItem>
              <GridLayoutItem style={{ width: "100%" }}>
                <Field
                  name="meta_description"
                  label="Meta Description"
                  component={FormTextArea}
                  rows={2}
                  resize={false}
                  validator={requiredValidator}
                />
              </GridLayoutItem>
              <GridLayoutItem style={{ width: "100%" }}>
                <Field
                  name="short_description"
                  label="Short Description"
                  component={FormTextField}
                  validator={requiredValidator}
                />
              </GridLayoutItem>
              <GridLayoutItem style={{ width: "100%" }}>
                <Field
                  id={"speciality_type_id"}
                  name={"speciality_type_id"}
                  label={"Speciality Type"}
                  component={FormMultiSelectionFiled}
                  validator={requiredValidator}
                  options={SpecialityTypeList?.map(
                    (specialitytype: ISpecialityType) => {
                      return {
                        value: specialitytype?.id,
                        label: specialitytype?.speciality_type,
                      };
                    }
                  )}
                />
              </GridLayoutItem>
              <GridLayoutItem style={{ width: "100%" }}>
                <Field
                  id={"is_show_home"}
                  name={"is_show_home"}
                  label={"Show Home"}
                  component={FormCheckbox}
                  // validator={requiredValidator}
                />
                <Field
                  id={"is_populer_tour"}
                  name={"is_populer_tour"}
                  label={"Populer Tour"}
                  component={FormCheckbox}
                  // validator={requiredValidator}
                />
              </GridLayoutItem>
              <GridLayoutItem style={{ width: "100%" }} colSpan={2}>
                <Field
                  name="long_description"
                  label="Long Description"
                  component={FormRichTextField}
                  validator={requiredValidator}
                />
              </GridLayoutItem>
              <GridLayoutItem style={{ width: "100%" }}>
                <Field
                  id={"isactive"}
                  name={"isactive"}
                  label={"Status"}
                  component={FormSelectionField}
                  validator={requiredValidator}
                  options={STATUSARRAY?.map((status: any) => {
                    return {
                      value: status?.value,
                      label: status?.label,
                    };
                  })}
                />
              </GridLayoutItem>
              {!TourID && (
                <>
                  <GridLayoutItem style={{ width: "100%" }}>
                    <Field
                      id={"main_image"}
                      name={"main_image"}
                      label={"Main Image"}
                      component={FormImageUpload}
                      validator={requiredValidator}
                    />
                  </GridLayoutItem>
                  <GridLayoutItem style={{ width: "100%" }}>
                    <Field
                      id={"other_image"}
                      name={"other_image"}
                      label={"Other Images"}
                      multiple
                      component={FormImageUpload}
                      validator={requiredValidator}
                    />
                  </GridLayoutItem>
                </>
              )}

              <GridLayoutItem
                colSpan={3}
                style={{
                  textAlign: "end",
                  marginTop: "20px",
                  width: "100%",
                }}
              >
                <Button
                  fillMode="outline"
                  themeColor="primary"
                  style={{ marginRight: 4 }}
                  onClick={() => navigate("/tour")}
                >
                  Cancel
                </Button>
                <ButtonWithLoading
                  label={TourID ? "Update" : "Add"}
                  type="submit"
                  disabled={!formRenderProps.allowSubmit || formloading}
                  loading={formloading}
                />
              </GridLayoutItem>
            </GridLayout>
          </ShadowCard>
          {/* <ShadowCard style={{ padding: 10, marginTop: 10 }}>
            <GridLayout
              style={{ marginRight: 20 }}
              gap={{ rows: 0, cols: 10 }}
              cols={[
                { width: "33.33%" },
                { width: "33.33%" },
                { width: "33.33%" },
              ]}
            >
              <GridLayoutItem colSpan={3}>
                <Typography.h4>{"Pickup Details"}</Typography.h4>
              </GridLayoutItem>
              <GridLayoutItem style={{ width: "100%" }}>
                <Field
                  name="PickupPlace"
                  label="Pickup Place"
                  component={FormTextField}
                />
              </GridLayoutItem>
              <GridLayoutItem style={{ width: "100%" }}>
                <Field
                  name="PickupFlightPlace"
                  label="Pickup Flight"
                  component={FormTextField}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  id={"PickupTime"}
                  name={"PickupTime"}
                  label={"Pickup Time"}
                  component={FormTimePicker}
                />
              </GridLayoutItem>
              <GridLayoutItem style={{ width: "100%" }}>
                <Field
                  name="PickupRemarks"
                  label="Pickup Remarks"
                  component={FormTextField}
                />
              </GridLayoutItem>
              <GridLayoutItem style={{ width: "100%" }}>
                <Field
                  name="DropPlace"
                  label="Drop Place"
                  component={FormTextField}
                />
              </GridLayoutItem>
              <GridLayoutItem style={{ width: "100%" }}>
                <Field
                  name="DropFlightPlace"
                  label="Drop Flight"
                  component={FormTextField}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  id={"DropTime"}
                  name={"DropTime"}
                  label={"Drop Time"}
                  component={FormTimePicker}
                />
              </GridLayoutItem>
              <GridLayoutItem style={{ width: "100%" }}>
                <Field
                  name="DropRemarks"
                  label="Drop Remarks"
                  component={FormTextField}
                />
              </GridLayoutItem>
              <GridLayoutItem
                colSpan={3}
                style={{
                  textAlign: "end",
                  marginTop: "20px",
                  width: "100%",
                }}
              >
                <RippleButton
                  fillMode="outline"
                  themeColor="primary"
                  style={{ marginRight: 4 }}
                  onClick={() => navigate("/tour")}
                >
                  Cancel
                </RippleButton>
                <ButtonWithLoading
                  label={TourID ? "Update" : "Add"}
                  type="submit"
                  disabled={!formRenderProps.allowSubmit || loading}
                  loading={loading}
                />
              </GridLayoutItem>
            </GridLayout>
          </ShadowCard> */}
        </FormElement>
      )}
    />
  );
};

export default TourInformation;
