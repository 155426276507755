import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import { IFlightPlace } from "../flightPlaceModel";

export const getAllFlightPlaces = createAsyncThunk(
  "FlightPlaces/FindAllFlightPlaces",
  async () => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/FlightPlaces/FindAllFlightPlaces`,
        {
          FlightPlacesName: "",
        }
      );
      return response.data?.Data;
    } catch (error: any) {
      console.error("Error Fetching FlightPlaces:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createFlightPlace = createAsyncThunk(
  "FlightPlaces/InsertFlightPlaces",
  async (FormData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/FlightPlaces/InsertFlightPlaces`,
        FormData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching FlightPlaces:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateFlightPlace = createAsyncThunk(
  "FlightPlaces/UpdateFlightPlaces",
  async (FormData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/FlightPlaces/UpdateFlightPlaces`,
        FormData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating FlightPlace:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getFlightPlaceById = createAsyncThunk(
  "FlightPlaces/FindByIDFlightPlaces",
  async (ID: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/FlightPlaces/FindByIDFlightPlaces`,
        { flightplace_guid: ID }
      );
      // SuccessToast(response.data?.Details || "Success")
      const result = response.data?.Data as any;
      return {
        ID: result.id,
        country_id: result.country_id,
        country_name: result.country_name,
        state_id: result.state_id,
        state_name: result.state_name,
        city_id: result.city_id,
        city_name: result.city_name,
        favourite_places: result.favourite_places,
        flightplace_guid: result.flightplace_guid,
        flightplace_name: result.flightplace_name,
        flightplace_code: result.flightplace_code,
        isactive:  result.isactive === true ? 1 : 2,
      } as IFlightPlace;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching FlightPlaces:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteFlightPlace = createAsyncThunk(
  "FlightPlaces/DeleteFlightPlaces",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/FlightPlaces/DeleteFlightPlaces`,
        { flightplace_guid: ID }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Deleting FlightPlace:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const findAllActiveFlightPlace = createAsyncThunk(
  "FlightPlaces/FindAllActiveFlightPlaces",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/FlightPlaces/FindAllActiveFlightPlaces`
      );
      // SuccessToast(response.data?.Details || "Success");
      return response.data.Data;
    } catch (error: any) {
      console.error("Error Deleting FlightPlaces:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const flightPlaceActiveInactive = createAsyncThunk(
  "FlightPlaces/ActiveInActiveFlightPlaces",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/FlightPlaces/ActiveInActiveFlightPlaces`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error active-inactive FlightPlace:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
