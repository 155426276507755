import React from "react";
import { PieChartOutlined, UserOutlined } from "@ant-design/icons";
import type { MenuProps } from "antd";
import { Layout, Menu } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { GrTransaction } from "react-icons/gr";
import { MdOutlineDashboard, MdOutlineTour } from "react-icons/md";

const { Sider } = Layout;

type MenuItem = Required<MenuProps>["items"][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[]
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
  } as MenuItem;
}

const items: MenuItem[] = [
  getItem("Dashboard", "", <PieChartOutlined />),
  getItem("User Manage", "usermanagement", <UserOutlined />, [
    getItem("User", "user"),
    getItem("User Type", "usertype"),
    getItem("Menu", "menu"),
    getItem("Rights", "rights"),
  ]),
  getItem("Masters", "masters", <MdOutlineDashboard />, [
    getItem("Nationality", "nationality"),
    getItem("City", "city"),
    getItem("State", "state"),
    getItem("Country", "country"),
    getItem("Flight Places", "flightplaces"),
    getItem("Customers", "usermanage"),
    getItem("Speciality Type", "specialitytype"),
    getItem("Room Type", "roomtype"),
    getItem("Room Category", "roomcategory"),
    getItem("Facility", "facility"),
    getItem("Destination", "destination"),
    getItem("Itinerary", "itinerary"),
  ]),
  getItem("Transaction", "transaction", <GrTransaction />, [
    getItem("Flight Booking", "flightbooking"),
    getItem("Abandoned Flight Booking", "abandonedflightbooking"),
  ]),
  getItem("Add Tour", "tour", <MdOutlineTour />),
];

interface HeaderProps {
  collapsed: boolean;
  setCollapsed: (value: boolean) => void;
}

const Header: React.FC<HeaderProps> = ({ collapsed, setCollapsed }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const onClick: MenuProps["onClick"] = (e) => {
    navigate(e.key);
  };

  /****************************ADD BY JYOTINDRA ******************************/
  const [openKeys, setOpenKeys] = React.useState<string[]>([]);
  const onOpenChange: MenuProps["onOpenChange"] = (keys) => {
    // Ensure only one menu item is open at a time
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (items.map((item) => item?.key).includes(latestOpenKey)) {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    } else {
      setOpenKeys(keys);
    }
  };
  /*************************************************************************/

  // const userResponseString = localStorage.getItem("UserRightsAssign") || "[]";
  // let userResponse: {
  //   menu_id: number;
  //   menu_key: string;
  //   menu_name: string;
  //   rights_id: string;
  // }[];

  // try {
  //   userResponse = JSON.parse(userResponseString);
  // } catch (error) {
  //   userResponse = [];
  // }

  // const allowedKeys = new Set(userResponse.map((item) => item.menu_key));

  // function filterMenuItems(menuItems: MenuItem[]): MenuItem[] {
  //   return menuItems.reduce((filteredItems, item: any) => {
  //     if (allowedKeys.has(item.key)) {
  //       if (item.children) {
  //         const filteredChildren = filterMenuItems(item.children);
  //         filteredItems.push({ ...item, children: filteredChildren });
  //       } else {
  //         filteredItems.push(item);
  //       }
  //     } else if (item.children) {
  //       const filteredChildren = filterMenuItems(item.children);
  //       if (filteredChildren.length > 0) {
  //         filteredItems.push({ ...item, children: filteredChildren });
  //       }
  //     }
  //     return filteredItems;
  //   }, [] as MenuItem[]);
  // }

  // let filteredItems = filterMenuItems(items);

  // const hasDashboardAccess = userResponse.some(
  //   (item) => item.menu_key === "dashboard"
  // );
  // if (hasDashboardAccess) {
  //   filteredItems = [
  //     getItem("Dashboard", "", <PieChartOutlined />),
  //     ...filteredItems,
  //   ];
  // }

  // function mapToGetItemFormat(menuItems: MenuItem[]): MenuItem[] {
  //   return menuItems.map((item: any) =>
  //     getItem(
  //       item.label,
  //       item.key,
  //       item.icon,
  //       item.children ? mapToGetItemFormat(item.children) : undefined
  //     )
  //   );
  // }

  // const formattedItems = mapToGetItemFormat(filteredItems);

  return (
    <Sider
      collapsible
      collapsed={collapsed}
      onCollapse={(value) => setCollapsed(value)}
    >
      <div className="drawer-logo">
        <h3 style={{ textAlign: "center", color: "white" }}>BS</h3>
      </div>
      <Menu
        className="sider-scroll"
        style={{
          overflow: "auto",
          height: "calc(100vh - 115px)",
        }}
        onClick={onClick}
        theme="dark"
        defaultSelectedKeys={[""]}
        onOpenChange={onOpenChange} // Add jyotindra
        openKeys={openKeys} // Add jyotindra
        selectedKeys={[location.pathname?.substring(1)?.split("/")[0]]}
        mode="inline"
        // items={formattedItems}
        items={items}
      />
    </Sider>
  );
};

export default Header;
