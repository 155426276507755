import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IFacility, IFacilityInitialState } from "./facilityModel";
import {
  ActiveInactiveFacility,
  createFacility,
  deleteFacility,
  getAllActiveFacilitys,
  getAllFacilitys,
  getFacilityByID,
  updateFacility,
} from "./services/facility.services";

const initialState: IFacilityInitialState = {
  loading: false,
  formLoading: false,
  error: "",
  FacilityList: [],
  FacilityID: null,
  FacilityGUID: "",
  FacilityDetail: {
    name: "",
    isactive: 1,
  },
};

const facilitySlice = createSlice({
  name: "facility",
  initialState,
  reducers: {
    clearFacilityDetails: (state) => {
      state.FacilityDetail = initialState.FacilityDetail;
    },
    setFacilityID: (state, action) => {
      state.FacilityID = action.payload;
    },
    clearFacilityID: (state) => {
      state.FacilityID = initialState.FacilityID;
    },
    setFacilityGUID: (state, action) => {
      state.FacilityGUID = action.payload;
    },
    clearFacilityGUID: (state) => {
      state.FacilityGUID = initialState.FacilityGUID;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllFacilitys.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllFacilitys.fulfilled,
      (state, action: PayloadAction<IFacility[]>) => {
        state.loading = false;
        state.FacilityList = action.payload || [];
      }
    );
    builder.addCase(getAllFacilitys.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.FacilityList = [];
    });
    builder.addCase(createFacility.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(createFacility.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(createFacility.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(updateFacility.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(updateFacility.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(updateFacility.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(deleteFacility.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteFacility.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteFacility.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getFacilityByID.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(getFacilityByID.fulfilled, (state, action: any) => {
      state.formLoading = false;
      state.FacilityDetail = action.payload;
    });
    builder.addCase(getFacilityByID.rejected, (state, action) => {
      state.formLoading = false;
      state.FacilityDetail = initialState.FacilityDetail;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getAllActiveFacilitys.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllActiveFacilitys.fulfilled,
      (state, action: PayloadAction<IFacility[]>) => {
        state.loading = false;
        state.FacilityList = action.payload || [];
      }
    );
    builder.addCase(getAllActiveFacilitys.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.FacilityList = [];
    });
    builder.addCase(ActiveInactiveFacility.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(ActiveInactiveFacility.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(ActiveInactiveFacility.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const {
  clearFacilityDetails,
  clearFacilityID,
  setFacilityID,
  clearFacilityGUID,
  setFacilityGUID,
} = facilitySlice.actions;
export default facilitySlice.reducer;
