import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { INationality, INationalityInitialState } from "./nationalityModel";
import {
  createNationality,
  deleteNationality,
  findAllActiveNationality,
  getAllNationalities,
  getNationalityById,
  NationalityActiveInactive,
  updateNationality,
} from "./services/nationality.services";

const initialState: INationalityInitialState = {
  loading: false,
  formLoading: false,
  error: "",
  NationalityList: [],
  NationalityID: null,
  NationalityDetail: {
    nationality_name: "",
    isactive: 1,
  },
};

const nationlaitySlice = createSlice({
  name: "nationality",
  initialState,
  reducers: {
    clearNationalityDetails: (state) => {
      state.NationalityDetail = initialState.NationalityDetail;
    },
    setNationalityID: (state, action) => {
      state.NationalityID = action.payload;
    },
    clearNationalityID: (state) => {
      state.NationalityID = initialState.NationalityID;
    },
  },
  extraReducers: (builder) => {
    /* For getAll Nationalities */
    builder.addCase(getAllNationalities.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllNationalities.fulfilled,
      (state, action: PayloadAction<INationality[]>) => {
        state.loading = false;
        state.NationalityList = action.payload || [];
      }
    );
    builder.addCase(getAllNationalities.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.NationalityList = [];
    });

    /* For getNationalityBy ID */
    builder.addCase(getNationalityById.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(getNationalityById.fulfilled, (state, action: any) => {
      state.formLoading = false;
      state.NationalityDetail = action.payload;
    });
    builder.addCase(getNationalityById.rejected, (state, action) => {
      state.formLoading = false;
      state.NationalityDetail = initialState.NationalityDetail;
      state.error = action.error.message || "Something went wrong";
    });

    /* For create Nationality */
    builder.addCase(createNationality.pending, (state) => {
      state.formLoading = false;
      state.error = "";
    });
    builder.addCase(createNationality.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(createNationality.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });

    /* For Update Nationality */
    builder.addCase(updateNationality.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(updateNationality.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(updateNationality.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });

    /* For Delete Nationality */
    builder.addCase(deleteNationality.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteNationality.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteNationality.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    /* For NationalityActiveInactive */
    builder.addCase(NationalityActiveInactive.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(NationalityActiveInactive.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(NationalityActiveInactive.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    /* For FindAllActiveNationality */
    builder.addCase(findAllActiveNationality.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      findAllActiveNationality.fulfilled,
      (state, action: PayloadAction<INationality[]>) => {
        state.loading = false;
        state.NationalityList = action.payload || [];
      }
    );
    builder.addCase(findAllActiveNationality.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.NationalityList = [];
    });
  },
});

export const { clearNationalityDetails, clearNationalityID, setNationalityID } =
  nationlaitySlice.actions;
export default nationlaitySlice.reducer;
