import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IRoomType, IRoomTypeInitialState } from "./roomTypeModel";
import {
  ActiveInactiveRoomType,
  createRoomType,
  deleteRoomType,
  getAllActiveRoomTypes,
  getAllRoomTypes,
  getRoomTypeByID,
  updateRoomType,
} from "./services/roomType.services";

const initialState: IRoomTypeInitialState = {
  loading: false,
  formLoading: false,
  error: "",
  RoomTypeList: [],
  RoomTypeID: null,
  RoomTypeGUID: "",
  RoomTypeDetail: {
    room_type: "",
    prefix: "",
    isactive: 1,
  },
};

const roomTypeSlice = createSlice({
  name: "roomType",
  initialState,
  reducers: {
    clearRoomTypeDetails: (state) => {
      state.RoomTypeDetail = initialState.RoomTypeDetail;
    },
    setRoomTypeID: (state, action) => {
      state.RoomTypeID = action.payload;
    },
    clearRoomTypeID: (state) => {
      state.RoomTypeID = initialState.RoomTypeID;
    },
    setRoomTypeGUID: (state, action) => {
      state.RoomTypeGUID = action.payload;
    },
    clearRoomTypeGUID: (state) => {
      state.RoomTypeGUID = initialState.RoomTypeGUID;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllRoomTypes.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllRoomTypes.fulfilled,
      (state, action: PayloadAction<IRoomType[]>) => {
        state.loading = false;
        state.RoomTypeList = action.payload || [];
      }
    );
    builder.addCase(getAllRoomTypes.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.RoomTypeList = [];
    });
    builder.addCase(createRoomType.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(createRoomType.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(createRoomType.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(updateRoomType.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(updateRoomType.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(updateRoomType.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(deleteRoomType.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteRoomType.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteRoomType.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getRoomTypeByID.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(getRoomTypeByID.fulfilled, (state, action: any) => {
      state.formLoading = false;
      state.RoomTypeDetail = action.payload;
    });
    builder.addCase(getRoomTypeByID.rejected, (state, action) => {
      state.formLoading = false;
      state.RoomTypeDetail = initialState.RoomTypeDetail;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getAllActiveRoomTypes.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllActiveRoomTypes.fulfilled,
      (state, action: PayloadAction<IRoomType[]>) => {
        state.loading = false;
        state.RoomTypeList = action.payload || [];
      }
    );
    builder.addCase(getAllActiveRoomTypes.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.RoomTypeList = [];
    });
    builder.addCase(ActiveInactiveRoomType.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(ActiveInactiveRoomType.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(ActiveInactiveRoomType.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const {
  clearRoomTypeDetails,
  clearRoomTypeID,
  setRoomTypeID,
  clearRoomTypeGUID,
  setRoomTypeGUID,
} = roomTypeSlice.actions;
export default roomTypeSlice.reducer;
