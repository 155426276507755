import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import { IItinerary } from "../itineraryModel";

export const getAllItinerary = createAsyncThunk(
  "Itinerary/FindAllItinerary",
  async () => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Itinerary/FindAllItinerary`,
        {
          itinerary_title: "",
        }
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Itinerary:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createItinerary = createAsyncThunk(
  "Itinerary/InsertItinerary",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Itinerary/InsertItinerary`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating Itinerary:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateItinerary = createAsyncThunk(
  "Itinerary/UpdateItinerary",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Itinerary/UpdateItinerary`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating itinerary:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteItinerary = createAsyncThunk(
  "Itinerary/DeleteItinerary",
  async (GUID: string) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Itinerary/DeleteItinerary`,
        { itinerary_guid: GUID }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting itinerary:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getItineraryByID = createAsyncThunk(
  "Itinerary/FindByIDItinerary",
  async (GUID: string) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Itinerary/FindByIDItinerary`,
        { itinerary_guid: GUID }
      );
      const result = response.data?.Data as any;
      return {
        id: result.id,
        itinerary_guid: result.itinerary_guid,
        sector_id: result.sector_id,
        sector_name: result.sector_name,
        itinerary_title: result.itinerary_title,
        description: result.description,
        isactive: result.isactive === true ? 1 : 2,
      } as IItinerary;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching Itinerary:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAllActiveItinerary = createAsyncThunk(
  "Itinerary/FindAllActiveItinerary",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/Itinerary/FindAllActiveItinerary`
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Itinerary:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const ActiveInactiveItinerary = createAsyncThunk(
  "Itinerary/ActiveInActiveItinerary",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Itinerary/ActiveInActiveItinerary`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error active-inactive Itinerary:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getItineraryBySectorID = createAsyncThunk(
  "Itinerary/FindAllItineraryBySectorId",
  async (sector_id: string) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Itinerary/FindAllItineraryBySectorId`,
        {
          sector_id,
        }
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Itinerary:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
