import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { IFlightPlace, IFlightPlacesInitialState } from "./flightPlaceModel";
import {
  flightPlaceActiveInactive,
  createFlightPlace,
  deleteFlightPlace,
  findAllActiveFlightPlace,
  getAllFlightPlaces,
  getFlightPlaceById,
  updateFlightPlace,
  
} from "./services/flightPlace.services";

const initialState: IFlightPlacesInitialState = {
  loading: false,
  formLoading: false,
  error: "",
  FlightPlaceList: [],
  FlightPlaceID: null,
  FlightPlaceDetail: {
    country_id: null,
    state_id: null,
    city_id: null,
    flightplace_name: "",
    favourite_places: 1,
    flightplace_code: "",
    isactive: 1,
  },
};

const flightPlaceSlice = createSlice({
  name: "flightPlace",
  initialState,
  reducers: {
    clearFlightPlaceDetails: (state) => {
      state.FlightPlaceDetail = initialState.FlightPlaceDetail;
    },
    setFlightPlaceID: (state, action) => {
      state.FlightPlaceID = action.payload;
    },
    clearFlightPlaceID: (state) => {
      state.FlightPlaceID = initialState.FlightPlaceID;
    },
  },
  extraReducers: (builder) => {
    /* For getAll FlightPlaces */
    builder.addCase(getAllFlightPlaces.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllFlightPlaces.fulfilled,
      (state, action: PayloadAction<IFlightPlace[]>) => {
        state.loading = false;
        state.FlightPlaceList = action.payload || [];
      }
    );
    builder.addCase(getAllFlightPlaces.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.FlightPlaceList = [];
    });

    /* For getFlightPlaceBy ID */
    builder.addCase(getFlightPlaceById.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(getFlightPlaceById.fulfilled, (state, action: any) => {
      state.formLoading = false;
      state.FlightPlaceDetail = action.payload;
    });
    builder.addCase(getFlightPlaceById.rejected, (state, action) => {
      state.formLoading = false;
      state.FlightPlaceDetail = initialState.FlightPlaceDetail;
      state.error = action.error.message || "Something went wrong";
    });

    /* For create FlightPlace */
    builder.addCase(createFlightPlace.pending, (state) => {
      state.formLoading = false;
      state.error = "";
    });
    builder.addCase(createFlightPlace.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(createFlightPlace.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });

    /* For Update FlightPlace */
    builder.addCase(updateFlightPlace.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(updateFlightPlace.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(updateFlightPlace.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });

    /* For Delete FlightPlace */
    builder.addCase(deleteFlightPlace.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteFlightPlace.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteFlightPlace.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    /* For FlightPlacesActiveInactive */
    builder.addCase(flightPlaceActiveInactive.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(flightPlaceActiveInactive.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(flightPlaceActiveInactive.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    /* For FindAllActiveFlightPlaces */
    builder.addCase(findAllActiveFlightPlace.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      findAllActiveFlightPlace.fulfilled,
      (state, action: PayloadAction<IFlightPlace[]>) => {
        state.loading = false;
        state.FlightPlaceList = action.payload || [];
      }
    );
    builder.addCase(findAllActiveFlightPlace.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.FlightPlaceList = [];
    });
  },
});

export const { clearFlightPlaceDetails, clearFlightPlaceID, setFlightPlaceID } =
  flightPlaceSlice.actions;
export default flightPlaceSlice.reducer;
