import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IDestination, IDestinationInitialState } from "./destinationModel";
import {
  ActiveInactiveDestination,
  createDestination,
  deleteDestination,
  DestinationImageUpload,
  getAllActiveDestinations,
  getAllDestinations,
  getDestinationByID,
  updateDestination,
} from "./services/destination.services";

const initialState: IDestinationInitialState = {
  loading: false,
  formLoading: false,
  error: "",
  DestinationList: [],
  DestinationID: null,
  DestinationGUID: "",
  DestinationDetail: {
    destination: "",
    tour_type: 2,
    description: "",
    meta_title: "",
    meta_description: "",
    meta_keywords: "",
    is_populer: 2,
    isactive: 1,
  },
};

const destinationSlice = createSlice({
  name: "destination",
  initialState,
  reducers: {
    clearDestinationDetails: (state) => {
      state.DestinationDetail = initialState.DestinationDetail;
    },
    setDestinationID: (state, action) => {
      state.DestinationID = action.payload;
    },
    clearDestinationID: (state) => {
      state.DestinationID = initialState.DestinationID;
    },
    setDestinationGUID: (state, action) => {
      state.DestinationGUID = action.payload;
    },
    clearDestinationGUID: (state) => {
      state.DestinationGUID = initialState.DestinationGUID;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllDestinations.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllDestinations.fulfilled,
      (state, action: PayloadAction<IDestination[]>) => {
        state.loading = false;
        state.DestinationList = action.payload || [];
      }
    );
    builder.addCase(getAllDestinations.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.DestinationList = [];
    });
    builder.addCase(createDestination.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(createDestination.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(createDestination.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(updateDestination.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(updateDestination.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(updateDestination.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(deleteDestination.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteDestination.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteDestination.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getDestinationByID.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(getDestinationByID.fulfilled, (state, action: any) => {
      state.formLoading = false;
      state.DestinationDetail = action.payload;
    });
    builder.addCase(getDestinationByID.rejected, (state, action) => {
      state.formLoading = false;
      state.DestinationDetail = initialState.DestinationDetail;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getAllActiveDestinations.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllActiveDestinations.fulfilled,
      (state, action: PayloadAction<IDestination[]>) => {
        state.loading = false;
        state.DestinationList = action.payload || [];
      }
    );
    builder.addCase(getAllActiveDestinations.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.DestinationList = [];
    });

    builder.addCase(ActiveInactiveDestination.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(ActiveInactiveDestination.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(ActiveInactiveDestination.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(DestinationImageUpload.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(DestinationImageUpload.fulfilled, (state) => {
      state.formLoading = false;
    });
    builder.addCase(DestinationImageUpload.rejected, (state, action) => {
      state.formLoading = false;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const {
  clearDestinationDetails,
  clearDestinationID,
  setDestinationID,
  clearDestinationGUID,
  setDestinationGUID,
} = destinationSlice.actions;
export default destinationSlice.reducer;
