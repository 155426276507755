// import React from "react";
import { Routes, Route } from "react-router-dom";
import Layout from "../components/layout/Layout";
// import Login from "../features/login/Login";
// import { openDialog } from "../components/dialog/dialogSlice";
// import { useAppDispatch } from "../app/hooks";
import { Button } from "@progress/kendo-react-buttons";
// import LogIn from "../features/login/Login";
import { SuccessToast } from "../components/toast/Toasts";
import Country from "../features/country/Country";
import User from "../features/user/User";
import CreateUser from "../features/user/CreateUser";
import UserType from "../features/userType/UserType";
import CreateUserType from "../features/userType/CreateUserType";
import Rights from "../features/rights/Rights";
import CreateRights from "../features/rights/CreateRights";
import Menu from "../features/menu/Menu";
import CreateMenu from "../features/menu/CreateMenu";
import City from "../features/city/City";
import State from "../features/state/State";
import PrivateRoute from "./PrivateRoute";
import Dashboard from "../features/dashboard/Dashboard";
import Nationality from "../features/nationality/Nationality";
import FlightPlace from "../features/FlightPlace/FlightPlace";
// NEW LOGIN
import LoginNew from "../features/login/LoginNew";
import UserManagement from "../features/userManagement/UserManagement";
import CreateUserManagement from "../features/userManagement/CreateUserManagement";
import FlightBooking from "../features/flightBooking/FlightBooking";
import ViewFlightBookig from "../features/flightBooking/ViewFlightBookig";
import SpecialityType from "../features/specialitytype/SpecialityType";
import RoomType from "../features/roomtype/RoomType";
import RoomCategory from "../features/roomcategory/RoomCategory";
import Facility from "../features/facility/Facility";
import CreateDestination from "../features/destination/CreateDestination";
import Destination from "../features/destination/Destination";
import Itinerary from "../features/itinerary/Itinerary";
import AbandonedFlightBooking from "../features/abandonedFlightBooking/AbandonedFlightBooking";
import ViewAbandonedFlightBooking from "../features/abandonedFlightBooking/ViewAbandonedFlightBooking";
import Tour from "../features/tour/Tour";
import CreateTour from "../features/tour/CreateTour";
// import CreateCountry from "../features/country/CreateCountry";

const MainRouter = () => {
  // const dispatch = useAppDispatch();
  return (
    <Routes>
      {/* <Route path="login" element={<LogIn />} /> */}
      <Route path="login" element={<LoginNew />} />
      <Route
        path="/"
        element={
          <PrivateRoute>
          <Layout />
          </PrivateRoute>
        }
      >
        <Route
          path="flightbooking/viewbooking/:id"
          element={<ViewFlightBookig />}
        />
        <Route
          path="abandonedflightbooking/viewbooking/:id"
          element={<ViewAbandonedFlightBooking />}
        />

        <Route index={true} element={<Dashboard />} />
        {/* <Route path="flightbooking/viewbooking/:id" element={<ViewFlightBookig />} /> */}
        <Route path="flightplaces" element={<FlightPlace />} />
        <Route path="flightbooking" element={<FlightBooking />} />
        <Route
          path="abandonedflightbooking"
          element={<AbandonedFlightBooking />}
        />
        <Route path="nationality" element={<Nationality />} />
        <Route path="country" element={<Country />} />
        <Route path="city" element={<City />} />
        <Route path="state" element={<State />} />
        <Route path="specialitytype" element={<SpecialityType />} />
        <Route path="roomtype" element={<RoomType />} />
        <Route path="roomcategory" element={<RoomCategory />} />
        <Route path="facility" element={<Facility />} />
        <Route path="destination" element={<Destination />} />
        <Route path="destination/edit" element={<CreateDestination />} />
        <Route path="destination/create" element={<CreateDestination />} />
        <Route path="itinerary" element={<Itinerary />} />
        <Route path="tour" element={<Tour />} />
        <Route path="tour/create" element={<CreateTour />} />
        <Route path="tour/edit" element={<CreateTour />} />
        <Route path="user" element={<User />} />
        <Route path="user/create" element={<CreateUser />} />
        <Route path="user/edit" element={<CreateUser />} />
        <Route path="usermanage" element={<UserManagement />} />
        <Route path="usermanage/create" element={<CreateUserManagement />} />
        <Route path="usermanage/edit" element={<CreateUserManagement />} />
        <Route path="usertype" element={<UserType />} />
        <Route path="usertype/create" element={<CreateUserType />} />
        <Route path="usertype/edit" element={<CreateUserType />} />
        <Route path="rights" element={<Rights />} />
        <Route path="rights/create" element={<CreateRights />} />
        <Route path="rights/edit" element={<CreateRights />} />
        <Route path="menu" element={<Menu />} />
        <Route path="menu/create" element={<CreateMenu />} />
        <Route path="menu/edit" element={<CreateMenu />} />
        {/* <Route path="country/create" element={<CreateCountry />} />
        <Route path="country/edit" element={<CreateCountry />} /> */}
        <Route
          path="about"
          element={
            <Button
              fillMode={"solid"}
              themeColor={"primary"}
              onClick={() => SuccessToast("Hello")}
              // onClick={() => dispatch(openDialog("testDialog"))}
            >
              Hello
            </Button>
          }
        />
        <Route path="*" element={<h1>404</h1>} />
      </Route>
    </Routes>
  );
};

export default MainRouter;
