import { configureStore } from "@reduxjs/toolkit";
import { getDefaultMiddleware } from "@reduxjs/toolkit";
import dialogSlice from "../components/dialog/dialogSlice";
import loginReducer from "../features/login/loginSlice";
import countrySlice from "../features/country/countrySlice";
import userSlice from "../features/user/userSlice";
import drawerSlice from "../components/drawer/drawerSlice";
import menuSlice from "../features/menu/menuSlice";
import rightsSlice from "../features/rights/rightsSlice";
import userTypeSlice from "../features/userType/userTypeSlice";
import citySlice from "../features/city/citySlice";
import stateSlice from "../features/state/stateSlice";
import muiDrawerSlice from "../components/muidrawer/muiDrawerSlice";
import nationalitySlice from "../features/nationality/nationalitySlice";
import flightPlaceSlice from "../features/FlightPlace/flightPlaceSlice";
import paginationSlice from "../features/pagination/paginationSlice";
import userManagementSlice from "../features/userManagement/userManagementSlice";
import flightBookingSlice from "../features/flightBooking/flightBookingSlice";
import specialityTypeSlice from "../features/specialitytype/specialityTypeSlice";
import roomTypeSlice from "../features/roomtype/roomTypeSlice";
import roomCategorySlice from "../features/roomcategory/roomCategorySlice";
import facilitySlice from "../features/facility/facilitySlice";
import destinationSlice from "../features/destination/destinationSlice";
import itinerarySlice from "../features/itinerary/itinerarySlice";
import abandonedFlightBookingSlice from "../features/abandonedFlightBooking/abandonedFlightBookingSlice";
import tourSlice from "../features/tour/tourSlice";

const customizedMiddleware = () =>
  getDefaultMiddleware({
    serializableCheck: false,
  });

const store = configureStore({
  reducer: {
    login: loginReducer,
    dialog: dialogSlice,
    drawer: drawerSlice,
    muidrawer: muiDrawerSlice,
    nationality: nationalitySlice,
    country: countrySlice,
    city: citySlice,
    state: stateSlice,
    user: userSlice,
    userM: userManagementSlice,
    menu: menuSlice,
    rights: rightsSlice,
    userType: userTypeSlice,
    flightPlace: flightPlaceSlice,
    flightBooking: flightBookingSlice,
    pagination: paginationSlice,
    specialityType: specialityTypeSlice,
    roomType: roomTypeSlice,
    roomCategory: roomCategorySlice,
    facility: facilitySlice,
    destination: destinationSlice,
    itinerary: itinerarySlice,
    abandonedFlightBooking: abandonedFlightBookingSlice,
    tour: tourSlice,
  },
  middleware: customizedMiddleware,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
