export const GENDER = [
  { label: "Male", value: "Male" },
  { label: "Female", value: "Female" },
];

export const STATUSARRAY = [
  { label: "Active", value: 1 },
  { label: "Inactive", value: 2 },
];
export const TOUR_TYPE = [
  { label: "International", value: 1 },
  { label: "Domestic", value: 2 },
];
export const TRAVEL_TYPE = [
  { label: "International", value: "INTERNATIONAL" },
  { label: "Domestic", value: "DOMESTIC" },
];

export const POPULARARRAY = [
  { label: "Popular", value: 1 },
  { label: "Not Popular", value: 2 },
];

export type ISelectionType = {
  value: number;
  label: string;
};

export const INSERT_OPT_TYPE = 1;
export const EDIT_OPT_TYPE = 2;
export const DELETE_OPT_TYPE = 3;
export const GET_BY_GUID_OPT_TYPE = 4;
export const GET_ALL_ACTIVE_WITH_FILTER_OPT_TYPE = 5;
export const GET_ALL_OPT_TYPE = 6;
export const ACTIVE_INACTIVE_OPT_TYPE = 7;

export const DRAWER_WIDTH = 400;

// export const INSERT_ACCESS = "1";
// export const EDIT_ACCESS = "2";
// export const DELETE_ACCESS = "3";
// export const PDF_DOWNLOAD_ACCESS = "4";
// export const EXCEL_EXPORT_ACCESS = "5";

export const INSERT_ACCESS = "2";
export const EDIT_ACCESS = "4";
export const DELETE_ACCESS = "7";
export const PDF_DOWNLOAD_ACCESS = "4";
export const EXCEL_EXPORT_ACCESS = "5";
export const INITIALS = ["Mr", "Mrs", "Master", "Ms"];
export const TRAVELLER_GENDER = [
  { label: "Male", value: 1 },
  { label: "Female", value: 2 },
];

export const PAYMENT_METHOD = [
  { value: 1, label: "netbanking" },
  { value: 2, label: "upi" },
  { value: 3, label: "card" },
  { value: 4, label: "wallet" },
  { value: 5, label: "emi" },
];

export const REFUND_STATUS = [
  { value: 1, label: "partial" },
  { value: 1, label: "full" },
];
