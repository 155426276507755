import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import { IRoomCategory } from "../roomCategoryModel";

export const getAllRoomCategories = createAsyncThunk(
  "RoomCategory/FindAllRoomCategory",
  async () => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/RoomCategory/FindAllRoomCategory`,
        {
          room_category: "",
        }
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Room Categories:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createRoomCategory = createAsyncThunk(
  "RoomCategory/InsertRoomCategory",
  async (RoomCategoryData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/RoomCategory/InsertRoomCategory`,
        RoomCategoryData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating room Category:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateRoomCategory = createAsyncThunk(
  "RoomCategory/UpdateRoomCategory",
  async (RoomCategoryData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/RoomCategory/UpdateRoomCategory`,
        RoomCategoryData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating room Category:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteRoomCategory = createAsyncThunk(
  "RoomCategory/DeleteRoomCategory",
  async (GUID: string) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/RoomCategory/DeleteRoomCategory`,
        { room_category_guid: GUID }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting room Category:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getRoomCategoryByID = createAsyncThunk(
  "RoomCategory/FindByIDRoomCategory",
  async (GUID: string) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/RoomCategory/FindByIDRoomCategory`,
        { room_category_guid: GUID }
      );
      const result = response.data?.Data as any;
      return {
        room_category_guid: result?.room_category_guid,
        room_category: result?.room_category,
        isactive: result?.isactive ? 1 : 2,
      } as IRoomCategory;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching room Category:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAllActiveRoomCategories = createAsyncThunk(
  "RoomCategory/FindAllActiveRoomCategory",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/RoomCategory/FindAllActiveRoomCategory`
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching room Categories:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const ActiveInactiveRoomCategory = createAsyncThunk(
  "RoomCategory/ActiveInActiveRoomCategory",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/RoomCategory/ActiveInActiveRoomCategory`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error active-inactive room Category:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
