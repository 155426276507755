import { Button } from "@progress/kendo-react-buttons";
import { Card, GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import React from "react";
import IconButton from "../../components/common/IconButton";
import { MdDelete } from "react-icons/md";
import { Field, FieldArrayRenderProps } from "@progress/kendo-react-form";
import {
  // emailValidator,
  // phoneValidator,
  requiredValidator,
} from "../../components/formFields/CommonValidator";
import FormTextField from "../../components/formFields/FormTextField";
import { closeDialog, openDialog } from "../../components/dialog/dialogSlice";
import AppDialog, { DialogCloseButton } from "../../components/dialog/Dialog";
import { Typography } from "@progress/kendo-react-common";
import { setTravelerInfoDeleteIndex } from "./userManagementSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import FormDatePicker from "../../components/formFields/FormDateField";
import FormSelectionField from "../../components/formFields/FormSelectionField";

import { INITIALS } from "../../_contstants/common";
import { deleteTravallerInfo } from "./services/userManagement.services";
import { ErrorToast, SuccessToast } from "../../components/toast/Toasts";

const TravellerInfoArray = (fieldArrayRenderProps: FieldArrayRenderProps) => {
  const dispatch = useAppDispatch();
  const TravelerInfoDeleteIndex = useAppSelector(
    (state) => state.userM.TravelerInfoDeleteIndex
  );
  const CountryList = useAppSelector((state) => state.country.CountryList);
  const dialogName = useAppSelector((state) => state.dialog.dialogName);

  // const handleDeleteAction = React.useCallback(
  //   (index: number) => {
  //     fieldArrayRenderProps.onRemove({ index: index });
  //     dispatch(closeDialog());
  //     dispatch(setTravelerInfoDeleteIndex(-1));
  //   },
  //   [fieldArrayRenderProps]
  // );

  const handleDeleteAction = React.useCallback(
    async (index: number) => {
      const TGUID =
        fieldArrayRenderProps?.value[index].traveller_information_guid;
        console.log(TGUID);
        
      if (TGUID) {
        try {
          const response = await dispatch(deleteTravallerInfo(TGUID));
          if (response.meta.requestStatus === "fulfilled") {
            SuccessToast(response?.payload?.Details || "Success");
            fieldArrayRenderProps.onRemove({ index: index });
            dispatch(closeDialog());
            dispatch(setTravelerInfoDeleteIndex(-1));
          }
        } catch (error: any) {
          ErrorToast(error?.response?.data?.Details || "Something went wrong");
          console.error("Error deleting Traveller Details:", error);
          return Promise.reject({
            message: error?.response?.data?.Details || "Something went wrong",
          });
        }
      } else {
        fieldArrayRenderProps.onRemove({ index: index });
        dispatch(closeDialog());
        dispatch(setTravelerInfoDeleteIndex(-1));
      }
    },
    [fieldArrayRenderProps]
  );

  const pushElementInarray = React.useCallback(() => {
    fieldArrayRenderProps.onPush({
      value: {
        first_name: "",
        last_name: "",
        initial: null,
        gender: null,
        email_id: "",
        mobile_no: "",
        date_of_birth: "",
        pan_card_no: "",
        passport_no: "",
        issue_country: null,
        expire_date: "",
      },
    });
  }, [fieldArrayRenderProps]);

  const handleOpenDeleteDialog = (index: any) => {
    dispatch(openDialog("travellerDeleteDialog"));
    dispatch(setTravelerInfoDeleteIndex(index));
  };

  return (
    <GridLayout cols={[{ width: "100%" }]}>
      {!fieldArrayRenderProps.value ? pushElementInarray() : null}
      {dialogName === "travellerDeleteDialog" && (
        <AppDialog>
          <>
            <Typography.h5>{"Delete Process Data"}</Typography.h5>
            <GridLayout>
              <GridLayoutItem>
                <Typography.p>
                  Are you sure you want to delete this?
                </Typography.p>
              </GridLayoutItem>
              <GridLayoutItem
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <DialogCloseButton themeColor="error" />
                <Button
                  fillMode={"solid"}
                  themeColor={"error"}
                  onClick={() => handleDeleteAction(TravelerInfoDeleteIndex)}
                  type="button"
                >
                  Delete
                </Button>
              </GridLayoutItem>
            </GridLayout>
          </>
        </AppDialog>
      )}
      {fieldArrayRenderProps.value &&
        fieldArrayRenderProps.value.length > 0 &&
        fieldArrayRenderProps.value.map((_: any, index: number) => (
          <GridLayoutItem key={index} style={{ marginTop: "5px" }}>
            <Card className="fieldarray-card">
              <GridLayout
                style={{ marginRight: 40, padding: 10 }}
                gap={{ rows: 0, cols: 10 }}
                cols={[
                  { width: "25%" },
                  { width: "25%" },
                  { width: "25%" },
                  { width: "25%" },
                ]}
              >
                <GridLayoutItem
                  colSpan={4}
                  style={{
                    textAlign: "right",
                  }}
                >
                  <IconButton
                    onClick={() => handleOpenDeleteDialog(index)}
                    disabled={fieldArrayRenderProps.value.length === 1}
                    themeColor={"error"}
                    size={"small"}
                    fillMode={"solid"}
                    type="button"
                    style={{ height: 24, width: 24 }}
                  >
                    <MdDelete
                      className="absolute-position"
                      style={{ fontSize: "16px" }}
                    />
                  </IconButton>
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name={`travellers_information.${index}.initial`}
                    maxLength="100"
                    label="Initial"
                    placeholder="Select initial"
                    component={FormSelectionField}
                    options={INITIALS?.map((i: any) => {
                      return {
                        value: i,
                        label: i,
                      };
                    })}
                    validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name={`travellers_information.${index}.first_name`}
                    label="First Name"
                    placeholder="i.e. John"
                    format="n3"
                    type="text"
                    component={FormTextField}
                    validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name={`travellers_information.${index}.last_name`}
                    label="Last Name"
                    placeholder="i.e. Doe"
                    format="n3"
                    type="text"
                    component={FormTextField}
                    validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name={`travellers_information.${index}.date_of_birth`}
                    label="Date of Birth"
                    type="text"
                    component={FormDatePicker}
                    validator={requiredValidator}
                  />
                </GridLayoutItem>

                <GridLayoutItem>
                  <Field
                    name={`travellers_information.${index}.pan_card_no`}
                    label="PAN NO"
                    placeholder="i.e. ABCDE1234F"
                    type="text"
                    component={FormTextField}
                    // validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name={`travellers_information.${index}.email_id`}
                    label="Email"
                    placeholder="i.e. example@gmail.com"
                    type="text"
                    component={FormTextField}
                    // validator={emailValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name={`travellers_information.${index}.mobile_no`}
                    label="Mobile No"
                    placeholder="i.e. 9876543210"
                    type="text"
                    component={FormTextField}
                    // validator={phoneValidator}
                  />
                </GridLayoutItem>

                <GridLayoutItem>
                  <Field
                    name={`travellers_information.${index}.passport_no`}
                    label="Passport No"
                    placeholder="i.e A1234567"
                    component={FormTextField}
                    // validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name={`travellers_information.${index}.issue_country`}
                    label="Issue Country"
                    placeholder="Select Country"
                    component={FormSelectionField}
                    // validator={requiredValidator}
                    options={CountryList?.map((item: any) => {
                      return {
                        value: item.id,
                        label: item.country_name,
                      };
                    })}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name={`travellers_information.${index}.expire_date`}
                    label="Expire Date"
                    component={FormDatePicker}
                    // validator={requiredValidator}
                  />
                </GridLayoutItem>
              </GridLayout>
            </Card>
          </GridLayoutItem>
        ))}
      <GridLayoutItem style={{ margin: "10px 0", textAlign: "left" }}>
        <Button
          fillMode={"solid"}
          themeColor={"primary"}
          onClick={pushElementInarray}
          type="button"
        >
          Add Traveller
        </Button>
      </GridLayoutItem>
    </GridLayout>
  );
};

export default TravellerInfoArray;
