import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import {
  ITour,
  ITourCostDetails,
  ITourDate,
  ITourItineraryDetails,
} from "../tourModel";
import moment from "moment";

export const getAllTours = createAsyncThunk("Tour/FindAllTour", async () => {
  try {
    const response = await axiosApiInstance.post(
      `${API_URL}/Tour/FindAllTour`,
      {
        tour_name: "",
      }
    );
    return response.data?.Data;
  } catch (error: any) {
    // ErrorToast(error?.response?.data?.Details || "Something went wrong");
    console.error("Error Fetching Tours:", error);
    return Promise.reject({
      message: error?.response?.data?.Details || "Something went wrong",
    });
  }
});

export const createTour = createAsyncThunk(
  "Tour/InsertTour",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Tour/InsertTour`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating tour:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateTour = createAsyncThunk(
  "Tour/UpdateTour",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Tour/UpdateTour`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating tour:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteTour = createAsyncThunk(
  "Tour/DeleteTour",
  async (tour_guid: string) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Tour/DeleteTour`,
        { tour_guid }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting tour:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getTourByID = createAsyncThunk(
  "Tour/FindByIDTour",
  async (tour_guid: string) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Tour/FindByIDTour`,
        {
          tour_guid,
        }
      );
      const result = response.data?.Data as any;
      return {
        ID: result.ID,
        tour_guid: result.tour_guid,
        sector_type: result.sector_type,
        sector_id: result.sector_id,
        destination: result.destination,
        travel_type: result.travel_type,
        tour_name: result.tour_name,
        tour_code: result.tour_code,
        no_of_nights: result.no_of_nights,
        no_of_days: result.no_of_days,
        short_description: result.short_description,
        long_description: result.long_description,
        meta_title: result.meta_title,
        meta_description: result.meta_description,
        meta_keywords: result.meta_keywords,
        tour_from_date: result?.tour_from_date
          ? moment(result?.tour_from_date, "YYYY-MM-DD").toDate()
          : "",
        tour_to_date: result?.tour_to_date
          ? moment(result?.tour_to_date, "YYYY-MM-DD").toDate()
          : "",
        facility_id: result.facility_id,
        facility: result.facility,
        is_show_home: result.is_show_home,
        is_populer_tour: result.is_populer_tour,
        inclusions: result.inclusions,
        exclusions: result.exclusions,
        tour_information: result.tour_information,
        terms_and_conditions: result.terms_and_conditions,
        speciality_type_id: result.speciality_type_id,
        TourImageViewEntity: result.TourImageViewEntity,
        important_notes: result.important_notes,
        isactive: result.isactive ? 1 : 2,
      } as ITour;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching tour:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const ActiveInactiveTour = createAsyncThunk(
  "Tour/ActiveInActive",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Tour/ActiveInActive`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error active-inactive tour:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAllActiveTours = createAsyncThunk(
  "Tour/FindAllActiveTour",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/Tour/FindAllActiveTour`
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching tours:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

// TOUR DATES
export const createTourDateArray = createAsyncThunk(
  "Tour/TourDateInsert",
  async (TourDateData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Tour/TourDateInsert`,
        TourDateData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error inserting tour date:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getActiveTourDatesByTourID = createAsyncThunk(
  "Tour/TourDateListByTourID",
  async (TourID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Tour/TourDateListByTourID`,
        {
          tour_id: TourID,
        }
      );
      const result = response.data?.Data as any;
      const TourDateDetails = result?.map((tourDate: any) => ({
        id: tourDate?.ID,
        tour_date_guid: tourDate.tour_date_guid,
        tour_id: tourDate?.tour_id,
        tour_date: tourDate?.tour_date
          ? moment(tourDate?.tour_date, "YYYY-MM-DD").toDate()
          : "",
        tour_date_code: tourDate.tour_date_code,
      })) as ITourDate[];
      return TourDateDetails || [];
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching tour:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
export const deleteTourDate = createAsyncThunk(
  "Tour/TourDateDelete",
  async (tour_date_guid: string) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Tour/TourDateDelete`,
        {
          tour_date_guid,
        }
      );

      if (response.data?.Status === 200) {
        SuccessToast(response.data?.Details || "Success");
        return response.data;
      } else {
        ErrorToast(response?.data?.Details || "Something went wrong");
        console.error(response?.data?.Details);
      }
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching tour:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
// TOUR RATE As TOUR COST
export const getTourRatesByTourID = createAsyncThunk(
  "Tour/TourCostListByTourID",
  async (tour_id: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Tour/TourCostListByTourID`,
        {
          tour_id,
        }
      );
      const result = response.data?.Data as ITourCostDetails[];
      return result || [];
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching tour rates:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const insertTourRate = createAsyncThunk(
  "Tour/TourCostInsert",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Tour/TourCostInsert`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error inserting tour rate:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateTourRate = createAsyncThunk(
  "Tour/TourCostUpdate",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Tour/TourCostUpdate`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating tour rate:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteTourRate = createAsyncThunk(
  "Tour/TourCostDelete",
  async (tour_cost_guid: string) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Tour/TourCostDelete`,
        {
          tour_cost_guid,
        }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting tour rate:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
// TOUR ITINERARY
export const insertTourItinerary = createAsyncThunk(
  "TourItinerary/InsertTourItinerary",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/TourItinerary/InsertTourItinerary`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error inserting tour itinerary:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateTourItinerary = createAsyncThunk(
  "TourItinerary/UpdateTourItinerary",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/TourItinerary/UpdateTourItinerary`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating tour itinerary:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteTourItinerary = createAsyncThunk(
  "TourItinerary/DeleteTourItinerary",
  async (tour_itinerary_guid: string) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/TourItinerary/DeleteTourItinerary`,
        {
          tour_itinerary_guid,
        }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting tour itinerary:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getTourItinerariesByTourItineraryGUID = createAsyncThunk(
  "TourItinerary/FindByIDTourItinerary",
  async (tour_itinerary_guid: string) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/TourItinerary/FindByIDTourItinerary`,
        {
          tour_itinerary_guid,
        }
      );
      const result = response.data?.Data as ITourCostDetails[];
      return result || [];
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching tour itineraries:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getTourItinerariesByTourID = createAsyncThunk(
  "TourItinerary/FindAllTourItineraryByTourId",
  async (TourID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/TourItinerary/FindAllTourItineraryByTourId`,
        {
          tour_id: TourID,
        }
      );
      const result = response.data?.Data as ITourItineraryDetails[];
      return result || [];
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching tour itineraries:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const uploadTourImages = createAsyncThunk(
  "Tour/TourImageUpload",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Tour/TourImageUpload`,
        formData
      );
      return response.data;
    } catch (error: any) {
      console.error("Error create attachment:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateTourPolicy = createAsyncThunk(
  "Tour/UpdateTourPolicy",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/Tour/UpdateTourPolicy`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating tour:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
