import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  IAbandonedFlightBooking,
  IAbandonedFlightBookingInitialState,
} from "./abandonedFlightBookingModel";
import {
  findAllActiveAbandonedFlightBooking,
  getAbandonedFlightBookingByBookingId,
} from "./services/abandonedFlightBooking.services";

const initialState: IAbandonedFlightBookingInitialState = {
  loading: false,
  formLoading: false,
  error: "",
  AbandonedFlightBookingList: [],
  AbandonedFlightBookingID: null,
  AbandonedFlightBookingDetail: {
    user_guid: "",
    booking_no: "",
    booking_date: "",
    amount: null,
    email_id: "",
    mobile_no: "",
    booking_status: "",
    gst_number: "",
    gst_email: "",
    gst_registred_name: "",
    gst_mobile_no: "",
    gst_address: "",
    base_fare: null,
    airline_gst: "",
    total_airline_tax: null,
    other_taxes: null,
    yr: null,
    oc: null,
    net_price: null,
    fare_rules: "",
    device_type_booking: null,
    from_flight_place: "",
    to_flight_place: "",
    payment_id: "", //Payment key Start from here.
    Entity: "",
    Currency: "",
    PaymentStatus: "",
    OrderId: "",
    InvoiceId: "",
    International: false,
    PaymentAmount: null,
    Method: "",
    AmountRefunded: null,
    RefundStatus: "",
    Captured: false,
    Description: "",
    CardId: "",
    Bank: "",
    Wallet: "",
    Vpa: "",
    Email: "",
    Contact: "",
    Fee: "",
    Tax: "",
    customer_id: "",
    CreatedAt: null,
    flight_details: [],
    passenger_details: [],
  },
};

const abandonedFlightBookingSlice = createSlice({
  name: "abandonedAbandonedFlightBooking",
  initialState,
  reducers: {
    clearAbandonedFlightBookingDetails: (state) => {
      state.AbandonedFlightBookingDetail =
        initialState.AbandonedFlightBookingDetail;
    },
    setAbandonedFlightBookingID: (state, action) => {
      state.AbandonedFlightBookingID = action.payload;
    },
    clearAbandonedFlightBookingID: (state) => {
      state.AbandonedFlightBookingID = initialState.AbandonedFlightBookingID;
    },
  },
  extraReducers: (builder) => {
    /* For FindAllActiveAbandonedFlightBooking */
    builder.addCase(findAllActiveAbandonedFlightBooking.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      findAllActiveAbandonedFlightBooking.fulfilled,
      (state, action: PayloadAction<IAbandonedFlightBooking[]>) => {
        state.loading = false;
        state.AbandonedFlightBookingList = action.payload || [];
      }
    );
    builder.addCase(
      findAllActiveAbandonedFlightBooking.rejected,
      (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Something went wrong";
        state.AbandonedFlightBookingList = [];
      }
    );

    /* For getAbandonedFlightBookingByBooking  ID */
    builder.addCase(getAbandonedFlightBookingByBookingId.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(
      getAbandonedFlightBookingByBookingId.fulfilled,
      (state, action: any) => {
        state.formLoading = false;
        state.AbandonedFlightBookingDetail = action.payload;
      }
    );
    builder.addCase(
      getAbandonedFlightBookingByBookingId.rejected,
      (state, action) => {
        state.formLoading = false;
        state.AbandonedFlightBookingDetail =
          initialState.AbandonedFlightBookingDetail;
        state.error = action.error.message || "Something went wrong";
      }
    );
  },
});

export const {
  clearAbandonedFlightBookingDetails,
  clearAbandonedFlightBookingID,
  setAbandonedFlightBookingID,
} = abandonedFlightBookingSlice.actions;
export default abandonedFlightBookingSlice.reducer;
