import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import { IState } from "../stateModel";

export const getAllStates = createAsyncThunk("State/FindAllState", async () => {
  try {
    const response = await axiosApiInstance.post(
      `${API_URL}/State/FindAllState`,
      {
        StateName: "",
      }
    );
    return response.data?.Data;
  } catch (error: any) {
    console.error("Error Fetching states:", error);
    return Promise.reject({
      message: error?.response?.data?.Details || "Something went wrong",
    });
  }
});

export const createState = createAsyncThunk(
  "State/CreateState",
  async (StateData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/State/InsertState`,
        StateData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching states:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateState = createAsyncThunk(
  "State/UpdateState",
  async (StateData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/State/UpdateState`,
        StateData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching states:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getStateById = createAsyncThunk(
  "State/GetStateById",
  async (ID: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/State/FindByIDState`,
        { state_guid:ID }
      );
      // SuccessToast(response.data?.Details || "Success")
      const result = response.data?.Data as any;
      return {
        state_guid: result.state_guid,
        country_id: result.country_id,
        state_name: result.state_name,
        isactive: result.isactive === true ? 1 : 2,
      } as IState;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching states:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteState = createAsyncThunk(
  "State/DeleteState",
  async (ID: number) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/State/DeleteState`,
        { state_guid:ID }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Deleting states:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const findAllActiveStates = createAsyncThunk(
  "State/FindAllActiveState",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/State/FindAllActiveState`
      );
      // SuccessToast(response.data?.Details || "Success")
      return response.data.Data;
    } catch (error: any) {
      console.error("Error Deleting states:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const stateActiveInactive = createAsyncThunk(
  "State/ActiveInActiveState",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/State/ActiveInActiveState`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error active-inactive state:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
