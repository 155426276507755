import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  IFlightBooking,
  IFlightBookingInitialState,
} from "./flightBookingModel";
import {
  findAllActiveFlightBooking,
  getFlightBookingByBookingId,
} from "./services/flightBooking.services";

const initialState: IFlightBookingInitialState = {
  loading: false,
  formLoading: false,
  error: "",
  FlightBookingList: [],
  FlightBookingID: null,
  FlightBookingDetail: {
    user_guid: "",
    booking_no: "",
    booking_date: "",
    amount: null,
    email_id: "",
    mobile_no: "",
    booking_status: "",
    gst_number: "",
    gst_email: "",
    gst_registred_name: "",
    gst_mobile_no: "",
    gst_address: "",
    base_fare: null,
    airline_gst: "",
    total_airline_tax: null,
    other_taxes: null,
    yr: null,
    oc: null,
    net_price: null,
    fare_rules: "",
    device_type_booking: null,
    from_flight_place: "",
    to_flight_place: "",
    payment_id: "", //Payment key Start from here.
    Entity: "",
    Currency: "",
    PaymentStatus: "",
    OrderId: "",
    InvoiceId: "",
    International: false,
    PaymentAmount: null,
    Method: "",
    AmountRefunded: null,
    RefundStatus: "",
    Captured: false,
    Description: "",
    CardId: "",
    Bank: "",
    Wallet: "",
    Vpa: "",
    Email: "",
    Contact: "",
    Fee: "",
    Tax: "",
    customer_id: "",
    CreatedAt: null,
    flight_details: [],
    passenger_details: [],
  },
};

const flightBookingSlice = createSlice({
  name: "flightBooking",
  initialState,
  reducers: {
    clearFlightBookingDetails: (state) => {
      state.FlightBookingDetail = initialState.FlightBookingDetail;
    },
    setFlightBookingID: (state, action) => {
      state.FlightBookingID = action.payload;
    },
    clearFlightBookingID: (state) => {
      state.FlightBookingID = initialState.FlightBookingID;
    },
  },
  extraReducers: (builder) => {
    /* For FindAllActiveFlightBooking */
    builder.addCase(findAllActiveFlightBooking.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      findAllActiveFlightBooking.fulfilled,
      (state, action: PayloadAction<IFlightBooking[]>) => {
        state.loading = false;
        state.FlightBookingList = action.payload || [];
      }
    );
    builder.addCase(findAllActiveFlightBooking.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.FlightBookingList = [];
    });

    /* For getFlightBookingByBooking  ID */
    builder.addCase(getFlightBookingByBookingId.pending, (state) => {
      state.formLoading = true;
      state.error = "";
    });
    builder.addCase(
      getFlightBookingByBookingId.fulfilled,
      (state, action: any) => {
        state.formLoading = false;
        state.FlightBookingDetail = action.payload;
      }
    );
    builder.addCase(getFlightBookingByBookingId.rejected, (state, action) => {
      state.formLoading = false;
      state.FlightBookingDetail = initialState.FlightBookingDetail;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const {
  clearFlightBookingDetails,
  clearFlightBookingID,
  setFlightBookingID,
} = flightBookingSlice.actions;
export default flightBookingSlice.reducer;
