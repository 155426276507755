import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { API_URL } from "../../app/config";
import { ILogin } from "./loginModel";
import { ErrorToast, SuccessToast } from "../../components/toast/Toasts";

const initialState = {
  loading: false,
  error: "",
  data: null,
  // otpData: null,
  // mobile_no: "",
  // resend: false,
};

export const generateOTP = createAsyncThunk(
  "UserLogin/OtpGenerate",
  async (mobile: any) => {
    try {
      const response = await axios.post(`${API_URL}/UserLogin/OtpGenerate`, {
        mobile_no: mobile,
      });
      SuccessToast(response.data?.Details || "Success");
      return response.data?.Data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error login:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
export const employeeLogin = createAsyncThunk(
  "EmployeeLogin/EmployeeLogin",
  async (data: ILogin) => {
    try {
      const response = await axios.post(`${API_URL}/EmployeeLogin/EmployeeLogin`, data);
      SuccessToast(response.data?.Details || "Success");
      return response.data?.Data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error login:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    // clearOTP: (state) => {
    //   state.otpData = initialState.otpData;
    // },
    // setResend: (state, action) => {
    //   state.resend = action.payload;
    // },
  },
  extraReducers: (builder) => {
    // builder.addCase(generateOTP.pending, (state) => {
    //   state.loading = true;
    // });
    // builder.addCase(
    //   generateOTP.fulfilled,
    //   (state, action: PayloadAction<any>) => {
    //     state.data = action.payload;
    //     state.loading = false;
    //     state.error = "";
    //     state.otpData = action.payload;
    //   }
    // );
    // builder.addCase(generateOTP.rejected, (state, action) => {
    //   state.loading = false;
    //   state.otpData = null;
    //   state.error = action.error.message || "Something went wrong";
    // });

    builder.addCase(employeeLogin.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      employeeLogin.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.data = action.payload;
        state.loading = false;
        state.error = "";
        const result = action.payload;
        if (result?.Token) {
          localStorage.setItem("Token", result?.Token);
          localStorage.setItem("RefreshToken", result?.RefreshToken);
          localStorage.setItem("UserGUID", result?.employee_guid);
          localStorage.setItem("UserID", result?.id);
          localStorage.setItem("Name", result?.employee_Name  );
          localStorage.setItem("UserName", result?.employee_Name);
          localStorage.setItem("EmailID", result?.emailid);
          localStorage.setItem("MobileNo", result?.mobileno);
          localStorage.setItem("IsUser", result?.isuser);
          localStorage.setItem(
            "UserRightsAssign",
            JSON.stringify(result?.UserRightsAssign )
          );
        }
      }
    );
    builder.addCase(employeeLogin.rejected, (state, action) => {
      state.loading = false;
      state.data = null;
      state.error = action.error.message || "Something went wrong";
    });
  },
});
// export const { clearOTP, setResend } = loginSlice.actions;
export default loginSlice.reducer;
