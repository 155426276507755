import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import React, { useEffect, useRef } from "react";
import { Button } from "@progress/kendo-react-buttons";
import { Typography } from "@progress/kendo-react-common";
import {
  clearDestinationDetails,
  clearDestinationGUID,
} from "./destinationSlice";
import {
  createDestination,
  DestinationImageUpload,
  getAllDestinations,
  getDestinationByID,
  updateDestination,
} from "./services/destination.services";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { closed } from "../../components/drawer/drawerSlice";
import { LoadingPanel } from "../../components/layout/Loading";
import ShadowCard from "../../components/common/ShadowCard";
import FormTextField from "../../components/formFields/FormTextField";
import { requiredValidator } from "../../components/formFields/CommonValidator";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import { POPULARARRAY, STATUSARRAY, TOUR_TYPE } from "../../_contstants/common";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import FormRichTextField from "../../components/formFields/FormRichTextField";
import FormImageUpload from "../../components/formFields/FormImageUpload";

const CreateDestination: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const gridRef = useRef<any>(null);
  const DestinationDetail = useAppSelector(
    (state) => state.destination.DestinationDetail
  );
  const DatinationGUID = location?.state?.DatinationGUID;
  const formLoading = useAppSelector((state) => state.destination.formLoading);

  useEffect(() => {
    return () => {
      dispatch(clearDestinationDetails());
      dispatch(clearDestinationGUID());
      dispatch(closed());
    };
  }, []);

  useEffect(() => {
    if (DatinationGUID) {
      dispatch(getDestinationByID(DatinationGUID));
    }
  }, [DatinationGUID]);

  const handleSubmit = async (values: any) => {
    const formData = {
      destination: values?.destination,
      tour_type: values?.tour_type === 1 ? "International" : "Domestic",
      description: values?.description,
      meta_title: values?.meta_title,
      meta_description: values?.meta_description,
      meta_keywords: values?.meta_keywords,
      is_populer: values?.is_populer === 1,
      isactive: values.isactive === 1,
    };
    try {
      if (!DatinationGUID) {
        const response = await dispatch(createDestination(formData));
        if (response?.payload?.Status === 201) {
          const DesImage = values?.destination_image;
          const BannerImage = values?.banner_image;
          const file = new FormData();
          file.append(
            "destination_guid",
            response?.payload?.Data?.destination_guid
          );
          if (DesImage?.length > 0 || BannerImage?.length > 0) {
            if (DesImage?.length > 0) {
              file.append("destination_image", DesImage[0].getRawFile());
            }
            if (BannerImage?.length > 0) {
              file.append("banner_image", BannerImage[0].getRawFile());
            }
            dispatch(DestinationImageUpload(file));
          }
          dispatch(closed());
          dispatch(clearDestinationDetails());
          dispatch(getAllDestinations());
          navigate(-1);
        }
      } else {
        const editPayload = {
          destination_guid: DatinationGUID,
          ...formData,
        };
        const response = await dispatch(updateDestination(editPayload));
        if (response?.payload?.Status === 200) {
          const DesImage = values?.destination_image;
          const BannerImage = values?.banner_image;
          const file = new FormData();
          file.append("destination_guid", DatinationGUID);
          if (DesImage?.length > 0 || BannerImage?.length > 0) {
            if (DesImage?.length > 0) {
              file.append("destination_image", DesImage[0].getRawFile());
            }
            if (BannerImage?.length > 0) {
              file.append("banner_image", BannerImage[0].getRawFile());
            }
            dispatch(DestinationImageUpload(file));
          }
          dispatch(closed());
          dispatch(clearDestinationDetails());
          dispatch(getAllDestinations());
          navigate(-1);
        }
      }
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      throw error;
    }
  };

  const handleFormClose = () => {
    dispatch(closed());
    navigate("/destination");
  };

  if (formLoading) return <LoadingPanel gridRef={gridRef} />;
  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={DestinationDetail}
      render={(formRenderProps: FormRenderProps) => (
        <FormElement>
          <ShadowCard style={{ padding: 12 }}>
            <GridLayout
              style={{ marginRight: 30 }}
              gap={{ rows: 0, cols: 10 }}
              cols={[
                { width: "25%" },
                { width: "25%" },
                { width: "25%" },
                { width: "25%" },
              ]}
            >
              <GridLayoutItem colSpan={4}>
                <Typography.h4 className="m-0">
                  {DatinationGUID ? "Update Destination" : "Create Destination"}
                </Typography.h4>
              </GridLayoutItem>
              <GridLayoutItem style={{ width: "100%" }}>
                <Field
                  name="destination"
                  label="Destination"
                  placeholder="Destination"
                  component={FormTextField}
                  validator={requiredValidator}
                />
              </GridLayoutItem>
              <GridLayoutItem style={{ width: "100%" }}>
                <Field
                  name="meta_title"
                  label="Meta Title"
                  placeholder="Meta Title"
                  component={FormTextField}
                  validator={requiredValidator}
                />
              </GridLayoutItem>
              <GridLayoutItem style={{ width: "100%" }}>
                <Field
                  name={"tour_type"}
                  component={FormSelectionField}
                  label={"Tour Type"}
                  options={TOUR_TYPE?.map((status: any) => {
                    return {
                      value: status.value,
                      label: status.label,
                    };
                  })}
                  validator={requiredValidator}
                />
              </GridLayoutItem>
              <GridLayoutItem style={{ width: "100%" }}>
                <Field
                  name="meta_keywords"
                  label="Meta Keywords"
                  placeholder="Meta Keywords"
                  component={FormTextField}
                  validator={requiredValidator}
                />
              </GridLayoutItem>
              <GridLayoutItem style={{ width: "100%" }} colSpan={2}>
                <Field
                  name="description"
                  label="Description"
                  placeholder="Description"
                  component={FormRichTextField}
                  validator={requiredValidator}
                />
              </GridLayoutItem>
              <GridLayoutItem style={{ width: "100%" }} colSpan={2}>
                <Field
                  name="meta_description"
                  label="Meta Description"
                  placeholder="Meta Description"
                  component={FormRichTextField}
                  validator={requiredValidator}
                />
              </GridLayoutItem>
              <GridLayoutItem style={{ width: "100%" }} colSpan={2}>
                <Field
                  name={"destination_image"}
                  component={FormImageUpload}
                  label={"Destination Image"}
                />
              </GridLayoutItem>
              <GridLayoutItem style={{ width: "100%" }}>
                <Field
                  name={"is_populer"}
                  component={FormSelectionField}
                  label={"Pupular"}
                  options={POPULARARRAY?.map((status: any) => {
                    return {
                      value: status.value,
                      label: status.label,
                    };
                  })}
                  validator={requiredValidator}
                />
              </GridLayoutItem>
              <GridLayoutItem style={{ width: "100%" }}>
                <Field
                  id={"isactive"}
                  name={"isactive"}
                  component={FormSelectionField}
                  label={"Status"}
                  validator={requiredValidator}
                  options={STATUSARRAY?.map((status: any) => {
                    return {
                      value: status.value,
                      label: status.label,
                    };
                  })}
                />
              </GridLayoutItem>
              <GridLayoutItem style={{ width: "100%" }} colSpan={2}>
                <Field
                  name={"banner_image"}
                  component={FormImageUpload}
                  label={"Banner Image"}
                />
              </GridLayoutItem>

              <GridLayoutItem
                colSpan={4}
                style={{
                  textAlign: "end",
                  marginTop: "10px",
                  width: "100%",
                }}
              >
                <Button
                  type="button"
                  fillMode={"outline"}
                  themeColor={"primary"}
                  style={{ marginRight: 5 }}
                  onClick={handleFormClose}
                >
                  Cancel
                </Button>
                <ButtonWithLoading
                  label={DatinationGUID ? "Update" : "Create"}
                  type="submit"
                  disabled={!formRenderProps.allowSubmit || formLoading}
                  loading={formLoading}
                />
              </GridLayoutItem>
            </GridLayout>
          </ShadowCard>
        </FormElement>
      )}
    />
  );
};

export default CreateDestination;
