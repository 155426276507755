import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ITour, ITourInitialState, ITourItineraryDetails } from "./tourModel";
import {
  ActiveInactiveTour,
  createTour,
  deleteTour,
  deleteTourItinerary,
  deleteTourRate,
  getActiveTourDatesByTourID,
  getAllActiveTours,
  getAllTours,
  getTourByID,
  getTourItinerariesByTourID,
  getTourItinerariesByTourItineraryGUID,
  getTourRatesByTourID,
  insertTourItinerary,
  insertTourRate,
  updateTour,
  updateTourItinerary,
  updateTourPolicy,
  updateTourRate,
  uploadTourImages,
} from "./services/tour.services";

const initialState: ITourInitialState = {
  loading: false,
  formloading: false,
  error: "",
  TourList: [],
  TourCostList: [],
  TourItineraryList: [],
  TourID: null,
  TourGUID: "",
  TourCostID: null,
  TourCostGUID: "",
  TourItineraryID: null,
  TourItineraryGUID: "",
  ActiveStep: 0,
  TourDateDetails: [],
  TourPlaceDetails: [],
  TourPolicyDetails: {
    tour_guid: "",
    inclusions: "",
    exclusions: "",
    tour_information: "",
    terms_and_conditions: "",
    important_notes: "",
  },
  TourCostDetails: {
    tour_id: null,
    from_date: "",
    to_date: "",
    rate_type: "",
    twin_sharing_rate: null,
    three_sharing_rate: null,
    four_sharing_rate: null,
    cwb_rate: null,
    cnb_rate: null,
    infant_rate: null,
  },
  TourItineraryDetails: {
    itinerary_id: null,
    tour_id: null,
    day: null,
    description: "",
    facility_id: "",
    isactive: 1,
  },
  deleteIndex: -1,
  TourDetail: {
    sector_type: "",
    sector_id: null,
    travel_type: "",
    tour_name: "",
    tour_code: "",
    speciality_type_id: "",
    no_of_nights: null,
    no_of_days: null,
    short_description: "",
    long_description: "",
    meta_title: "",
    meta_description: "",
    meta_keywords: "",
    tour_from_date: "",
    tour_to_date: "",
    facility_id: "",
    is_show_home: false,
    is_populer_tour: false,
    inclusions: "",
    exclusions: "",
    tour_information: "",
    terms_and_conditions: "",
    important_notes: "",
    TourImageViewEntity: [],
    isactive: 1,
  },
};

const tourSlice = createSlice({
  name: "tour",
  initialState,
  reducers: {
    clearTourInformation: (state) => {
      state.TourDetail = initialState.TourDetail;
    },
    clearTourDateDetails: (state) => {
      state.TourDateDetails = initialState.TourDateDetails;
    },
    clearTourPlaceDetails: (state) => {
      state.TourPlaceDetails = initialState.TourPlaceDetails;
    },
    clearTourPolicyDetails: (state) => {
      state.TourPolicyDetails = initialState.TourPolicyDetails;
    },
    setTourID: (state, action) => {
      state.TourID = action.payload;
    },
    clearTourID: (state) => {
      state.TourID = initialState.TourID;
    },
    setTourGUID: (state, action) => {
      state.TourGUID = action.payload;
    },
    clearTourGUID: (state) => {
      state.TourGUID = initialState.TourGUID;
    },
    setTourCostID: (state, action) => {
      state.TourCostID = action.payload;
    },
    clearTourCostID: (state) => {
      state.TourCostID = initialState.TourCostID;
    },
    setTourCostGUID: (state, action) => {
      state.TourCostGUID = action.payload;
    },
    clearTourCostGUID: (state) => {
      state.TourCostGUID = initialState.TourCostGUID;
    },
    setTourCostDetails: (state, action) => {
      state.TourCostDetails = action.payload;
    },
    setTourPolicyDetails: (state, action) => {
      state.TourPolicyDetails = action.payload;
    },
    clearTourCostDetails: (state) => {
      state.TourCostDetails = initialState.TourCostDetails;
    },

    setTourItineraryID: (state, action) => {
      state.TourItineraryID = action.payload;
    },
    clearTourItineraryID: (state) => {
      state.TourItineraryID = initialState.TourItineraryID;
    },
    setTourItineraryGUID: (state, action) => {
      state.TourItineraryGUID = action.payload;
    },
    clearTourItineraryGUID: (state) => {
      state.TourItineraryGUID = initialState.TourItineraryGUID;
    },
    setTourItineraryDetails: (state, action) => {
      state.TourItineraryDetails = action.payload;
    },
    clearTourItineraryDetails: (state) => {
      state.TourItineraryDetails = initialState.TourItineraryDetails;
    },
    clearTourItineraryList: (state) => {
      state.TourItineraryList = initialState.TourItineraryList;
    },
    setActiveStep: (state, action) => {
      state.ActiveStep = action.payload;
    },
    clearActiveStep: (state) => {
      state.ActiveStep = initialState.ActiveStep;
    },
    increaseActiveStep: (state) => {
      state.ActiveStep = state.ActiveStep + 1;
    },
    decreaseActiveStep: (state) => {
      state.ActiveStep = state.ActiveStep - 1;
    },
    setDeleteIndex: (state, action) => {
      state.deleteIndex = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllTours.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllTours.fulfilled,
      (state, action: PayloadAction<ITour[]>) => {
        state.loading = false;
        state.TourList = action.payload || [];
      }
    );
    builder.addCase(getAllTours.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.TourList = [];
    });
    builder.addCase(createTour.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(createTour.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(createTour.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(updateTour.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(updateTour.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(updateTour.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(deleteTour.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteTour.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteTour.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(getTourByID.pending, (state) => {
      state.formloading = true;
      state.error = "";
    });
    builder.addCase(getTourByID.fulfilled, (state, action: any) => {
      state.formloading = false;
      state.TourDetail = action.payload;
    });
    builder.addCase(getTourByID.rejected, (state, action) => {
      state.formloading = false;
      state.TourDetail = initialState.TourDetail;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(ActiveInactiveTour.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(ActiveInactiveTour.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(ActiveInactiveTour.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(getActiveTourDatesByTourID.pending, (state) => {
      state.formloading = true;
      state.error = "";
    });
    builder.addCase(
      getActiveTourDatesByTourID.fulfilled,
      (state, action: any) => {
        state.formloading = false;
        state.TourDateDetails = action.payload;
      }
    );
    builder.addCase(getActiveTourDatesByTourID.rejected, (state, action) => {
      state.formloading = false;
      state.TourDateDetails = initialState.TourDateDetails;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(getTourItinerariesByTourItineraryGUID.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getTourItinerariesByTourItineraryGUID.fulfilled,
      (state, action: any) => {
        state.loading = false;
        state.TourItineraryDetails = action.payload;
      }
    );
    builder.addCase(
      getTourItinerariesByTourItineraryGUID.rejected,
      (state, action) => {
        state.loading = false;
        state.TourItineraryDetails = initialState.TourItineraryDetails;
        state.error = action.error.message || "Something went wrong";
      }
    );

    // Check from here is remaining
    builder.addCase(getAllActiveTours.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getAllActiveTours.fulfilled,
      (state, action: PayloadAction<ITour[]>) => {
        state.loading = false;
        state.TourList = action.payload || [];
      }
    );
    builder.addCase(getAllActiveTours.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.TourList = [];
    });
    builder.addCase(getTourItinerariesByTourID.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(
      getTourItinerariesByTourID.fulfilled,
      (state, action: PayloadAction<ITourItineraryDetails[]>) => {
        state.loading = false;
        state.TourItineraryList = action.payload || [];
      }
    );
    builder.addCase(getTourItinerariesByTourID.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.TourItineraryList = initialState.TourItineraryList;
    });

    builder.addCase(getTourRatesByTourID.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getTourRatesByTourID.fulfilled, (state, action: any) => {
      state.loading = false;
      state.TourCostList = action.payload;
    });
    builder.addCase(getTourRatesByTourID.rejected, (state, action) => {
      state.loading = false;
      state.TourCostList = initialState.TourCostList;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(insertTourRate.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(insertTourRate.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(insertTourRate.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(updateTourRate.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(updateTourRate.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(updateTourRate.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(deleteTourRate.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteTourRate.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteTourRate.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(insertTourItinerary.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(insertTourItinerary.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(insertTourItinerary.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(updateTourItinerary.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(updateTourItinerary.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(updateTourItinerary.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(deleteTourItinerary.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(deleteTourItinerary.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteTourItinerary.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });

    builder.addCase(uploadTourImages.pending, (state) => {
      state.formloading = true;
      state.error = "";
    });
    builder.addCase(uploadTourImages.fulfilled, (state) => {
      state.formloading = false;
    });
    builder.addCase(uploadTourImages.rejected, (state, action) => {
      state.formloading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(updateTourPolicy.pending, (state) => {
      state.formloading = true;
      state.error = "";
    });
    builder.addCase(updateTourPolicy.fulfilled, (state) => {
      state.formloading = false;
    });
    builder.addCase(updateTourPolicy.rejected, (state, action) => {
      state.formloading = false;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const {
  clearTourInformation,
  clearTourDateDetails,
  clearTourPlaceDetails,
  clearTourID,
  setTourID,
  clearTourGUID,
  setTourGUID,
  setTourCostID,
  clearTourCostID,
  setTourCostDetails,
  clearTourCostDetails,
  setTourItineraryID,
  clearTourItineraryID,
  clearTourItineraryList,
  setTourItineraryDetails,
  clearTourItineraryDetails,
  setDeleteIndex,
  setActiveStep,
  clearActiveStep,
  increaseActiveStep,
  decreaseActiveStep,
  clearTourItineraryGUID,
  setTourItineraryGUID,
  clearTourCostGUID,
  setTourCostGUID,
  clearTourPolicyDetails,
  setTourPolicyDetails,
} = tourSlice.actions;
export default tourSlice.reducer;
