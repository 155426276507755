import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ShadowCard from "../../../components/common/ShadowCard";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Typography } from "@progress/kendo-react-common";
import { requiredValidator } from "../../../components/formFields/CommonValidator";
import { Button } from "@progress/kendo-react-buttons";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { updateTourPolicy } from "../services/tour.services";
import { clearTourPolicyDetails, decreaseActiveStep, setTourPolicyDetails } from "../tourSlice";
import ButtonWithLoading from "../../../components/common/ButtonWithLoading";
import FormRichTextField from "../../../components/formFields/FormRichTextField";

const TourPolicy = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  //   const TourID = location.state?.TourID;
  const TourGUID = location.state?.TourGUID;
  const formloading = useAppSelector((state) => state.tour.formloading);
  const TourPolicyDetails = useAppSelector(
    (state) => state?.tour?.TourPolicyDetails
  );
  const TourDetails = useAppSelector((state) => state?.tour?.TourDetail);
  useEffect(() => {
    const policy = {
      tour_guid: TourDetails?.tour_guid,
      inclusions: TourDetails?.inclusions,
      exclusions: TourDetails?.exclusions,
      tour_information: TourDetails?.tour_information,
      terms_and_conditions: TourDetails?.terms_and_conditions,
      important_notes: TourDetails?.important_notes,
    };
    dispatch(setTourPolicyDetails(policy));

    return () => {
      dispatch(clearTourPolicyDetails());
    };
  }, []);

  const [formKey, setFormKey] = useState(1);
  useEffect(() => {
    setFormKey(formKey + 1);
  }, [TourPolicyDetails]);

  const handleSubmit = async (values: any) => {
    const formdata = {
      tour_guid: values?.tour_guid ?? TourGUID,
      inclusions: values.inclusions,
      exclusions: values.exclusions,
      tour_information: values.tour_information,
      terms_and_conditions: values.terms_and_conditions,
      important_notes: values.important_notes,
    };
    try {
      if (TourGUID) {
        const response = await dispatch(updateTourPolicy(formdata));
        if (response?.meta?.requestStatus === "fulfilled") {
          //   console.log("response", response);
          navigate("/tour");
        }
      }
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      throw error;
    }
  };
  return (
    <Form
      onSubmit={handleSubmit}
      key={formKey}
      initialValues={TourPolicyDetails}
      render={(formRenderProps: FormRenderProps) => (
        <FormElement>
          <ShadowCard style={{ padding: 10 }}>
            <GridLayout
              style={{ marginRight: 30 }}
              gap={{ rows: 0, cols: 10 }}
              cols={[{ width: "50%" }, { width: "50%" }]}
            >
              <GridLayoutItem colSpan={2}>
                <Typography.h4>{"Policy"}</Typography.h4>
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  name="inclusions"
                  label="Inclusions"
                  component={FormRichTextField}
                  validator={requiredValidator}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  name="exclusions"
                  label="Exclusions"
                  component={FormRichTextField}
                  validator={requiredValidator}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  name="tour_information"
                  label="Tour Information"
                  component={FormRichTextField}
                  validator={requiredValidator}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  name="terms_and_conditions"
                  label="Terms & Conditions"
                  component={FormRichTextField}
                  validator={requiredValidator}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  name="important_notes"
                  label="Important Notes"
                  component={FormRichTextField}
                  validator={requiredValidator}
                />
              </GridLayoutItem>

              <GridLayoutItem
                style={{ textAlign: "end", marginTop: 20 }}
                colSpan={2}
              >
                <Button
                  type="button"
                  fillMode="outline"
                  themeColor="primary"
                  style={{ marginRight: 10 }}
                  onClick={() => dispatch(decreaseActiveStep())}
                >
                  Previous
                </Button>

                <ButtonWithLoading
                  label={"Save"}
                  type="submit"
                  disabled={!formRenderProps.allowSubmit || formloading}
                  loading={formloading}
                />
                <Button
                  fillMode="solid"
                  themeColor="primary"
                  style={{ marginLeft: 10 }}
                  onClick={() => navigate("/tour")}
                >
                  Go to Tour List
                </Button>
              </GridLayoutItem>
            </GridLayout>
            {/* <DayChangeWatcher formRenderProps={formRenderProps} /> */}
          </ShadowCard>
        </FormElement>
      )}
    />
  );
};

export default TourPolicy;
