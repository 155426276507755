import React, { useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { LoadingPanel } from "../../components/layout/Loading";
import { Typography } from "@progress/kendo-react-common";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  FieldArray,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import FormTextField from "../../components/formFields/FormTextField";
import { requiredValidator } from "../../components/formFields/CommonValidator";
// import FormImageUpload from "../../components/formFields/FormImageUpload";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import { getUserMById, updateUserM } from "./services/userManagement.services";
import { clearUserMDetails } from "./userManagementSlice";
import ShadowCard from "../../components/common/ShadowCard";
import FormSelectionField from "../../components/formFields/FormSelectionField";
import { STATUSARRAY, TRAVELLER_GENDER } from "../../_contstants/common";
import FormDatePicker from "../../components/formFields/FormDateField";
import { findAllActiveNationality } from "../nationality/services/nationality.services";
import { findAllActiveCity } from "../city/services/city.services";
import TravellerInfoArray from "./TravellerInfoArray";
import { getAllActiveCountries } from "../country/services/country.services";
import { findAllActiveStates } from "../state/services/state.services";
import moment from "moment";

const CreateUserManagement: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const user_guid = location.state?.user_guid;
  // const user_guid = "72647f6f-bd6e-4a7a-95ca-5118e8ef5da0";
  const gridRef = useRef<any>(null);
  const dispatch = useAppDispatch();

  const [formKey, setFormKey] = React.useState(1);

  const loading = useAppSelector((state) => state.userM.loading);
  const UserDetails = useAppSelector((state) => state.userM.UserMDetails);

  const NationalityList = useAppSelector(
    (state) => state.nationality.NationalityList
  );
  const CityList = useAppSelector((state) => state.city.CityList);
  const StateList = useAppSelector((state) => state.state.StateList);

  useEffect(() => {
    if (user_guid) {
      dispatch(getUserMById(user_guid));
    }
  }, [user_guid]);

  useEffect(() => {
    setFormKey(formKey + 1);
  }, [UserDetails]);

  useEffect(() => {
    dispatch(findAllActiveNationality());
    dispatch(findAllActiveCity());
    dispatch(findAllActiveStates());
    dispatch(getAllActiveCountries());
    return () => {
      dispatch(clearUserMDetails());
    };
  }, []);

  const handleSubmit = async (values: any) => {
    const formData = {
      Id: location.state?.ID ? location.state?.ID : 0,
      first_name: values.first_name ?? "",
      last_name: values.last_name ?? "",
      date_of_birth: values.date_of_birth
        ? moment(values.date_of_birth).format("YYYY-MM-DD")
        : null,
      gender: values.gender,
      nationality_id: values.nationality_id ?? "",
      city_id: values.city_id,
      profile_photo: values.profile_photo,
      is_email_verified: values.is_email_verified,
      billing_address: values.billing_address,
      billing_state_id: values.billing_state_id,
      billing_city_id: values.billing_city_id,
      billing_pan_card_no: values.billing_pan_card_no,
      billing_pincode: values.billing_pincode,
      billing_gst_no: values.billing_gst_no,
      isactive: values.isactive === 1,
      travellers_information: values.travellers_information.map(
        (tInfo: any) => {
          return {
            id: tInfo.id ?? 0,
            user_id: tInfo.user_id ?? location.state?.ID,
            first_name: tInfo.first_name,
            last_name: tInfo.last_name,
            initial: tInfo.initial,
            gender:
              tInfo.initial === "Mr" || tInfo.initial === "Master" ? 1 : 2,
            email_id: tInfo.email_id,
            mobile_no: tInfo.mobile_no,
            date_of_birth: tInfo.date_of_birth
              ? moment(tInfo.date_of_birth).format("YYYY-MM-DD")
              : null,
            pan_card_no: tInfo.pan_card_no,
            passport_no: tInfo.passport_no,
            issue_country: tInfo.issue_country,
            expire_date: tInfo.expire_date
              ? moment(tInfo.expire_date).format("YYYY-MM-DD")
              : null,
          };
        }
      ),
    };
    if (user_guid) {
      const editPayload = {
        user_guid: user_guid,
        ...formData,
      };
      const response = await dispatch(updateUserM(editPayload));
      if (response?.meta?.requestStatus === "fulfilled") {
        navigate(-1);
      }
    } else {
      //   const response = await dispatch(createUser(insertPayload));
      //   if (response?.meta?.requestStatus === "fulfilled") {
      //     navigate(-1);
      //   }
    }
  };

  if (loading) return <LoadingPanel gridRef={gridRef} />;
  return (
    <>
      <Form
        key={formKey}
        onSubmit={handleSubmit}
        initialValues={UserDetails}
        render={(formRenderProps: FormRenderProps) => (
          <FormElement>
            <ShadowCard style={{ padding: 12 }}>
              <GridLayout
                style={{ marginRight: 20 }}
                gap={{ rows: 0, cols: 10 }}
                cols={[
                  { width: "33.33%" },
                  { width: "33.33%" },
                  { width: "33.33%" },
                ]}
              >
                <GridLayoutItem colSpan={3}>
                  <Typography.h4>
                    {user_guid ? "Update User" : "Create User"}
                  </Typography.h4>
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="first_name"
                    maxLength="100"
                    label="First Name"
                    component={FormTextField}
                    validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="last_name"
                    maxLength="100"
                    label="Last Name"
                    component={FormTextField}
                    validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="date_of_birth"
                    maxLength="100"
                    label="Date of Birth"
                    component={FormDatePicker}
                    validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="gender"
                    maxLength="100"
                    label="Gender"
                    component={FormSelectionField}
                    options={TRAVELLER_GENDER?.map((gen: any) => {
                      return {
                        value: gen?.value,
                        label: gen?.label,
                      };
                    })}
                    validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="nationality_id"
                    maxLength="100"
                    label="Nationality"
                    component={FormSelectionField}
                    options={NationalityList?.map((item: any) => {
                      return {
                        value: item?.id,
                        label: item?.nationality_name,
                      };
                    })}
                    // validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="city_id"
                    maxLength="100"
                    label="City"
                    component={FormSelectionField}
                    options={CityList?.map((item: any) => {
                      return {
                        value: item?.id,
                        label: item?.city_name,
                      };
                    })}
                    // validator={requiredValidator}
                  />
                </GridLayoutItem>

                <GridLayoutItem>
                  <Field
                    name="isactive"
                    label="Status"
                    component={FormSelectionField}
                    validator={requiredValidator}
                    options={STATUSARRAY?.map((status: any) => {
                      return {
                        value: status?.value,
                        label: status?.label,
                      };
                    })}
                  />
                </GridLayoutItem>
              </GridLayout>
            </ShadowCard>
            <ShadowCard style={{ padding: 12, marginTop: 10 }}>
              <GridLayout
                style={{ marginRight: 20 }}
                gap={{ rows: 0, cols: 10 }}
                cols={[
                  { width: "33.33%" },
                  { width: "33.33%" },
                  { width: "33.33%" },
                ]}
              >
                <GridLayoutItem colSpan={3}>
                  <Typography.h4>Billing Details</Typography.h4>
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="billing_state_id"
                    maxLength="100"
                    label="Billing State"
                    placeholder="Select State"
                    component={FormSelectionField}
                    options={StateList?.map((item: any) => {
                      return {
                        value: item?.id,
                        label: item?.state_name,
                      };
                    })}
                    // validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="billing_city_id"
                    maxLength="100"
                    label="Billing City"
                    placeholder="Select City"
                    component={FormSelectionField}
                    options={CityList?.map((item: any) => {
                      return {
                        value: item?.id,
                        label: item?.city_name,
                      };
                    })}
                    // validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="billing_pan_card_no"
                    type="text"
                    label="Billing PAN No"
                    placeholder="i.e ABCDE1234F"
                    component={FormTextField}
                    validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="billing_pincode"
                    type="text"
                    placeholder="i.e 382408"
                    label="Billing Pincode"
                    component={FormTextField}
                    validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="billing_gst_no"
                    type="text"
                    label="Billing GST NO"
                    placeholder="i.e 1234567890A1Z2"
                    component={FormTextField}
                    validator={requiredValidator}
                  />
                </GridLayoutItem>
                <GridLayoutItem>
                  <Field
                    name="billing_address"
                    type="text"
                    label="Billing Address"
                    placeholder="Billing Address"
                    component={FormTextField}
                    // validator={requiredValidator}
                  />
                </GridLayoutItem>
              </GridLayout>
            </ShadowCard>
            <ShadowCard style={{ marginTop: 10 }}>
              <GridLayoutItem colSpan={6}>
                <Typography.h4>{"Travellers Details"}</Typography.h4>
              </GridLayoutItem>
              <FieldArray
                formRenderProps={formRenderProps}
                component={TravellerInfoArray}
                name="travellers_information"
              />
            </ShadowCard>
            <ShadowCard style={{ padding: 12, marginTop: 10 }}>
              <GridLayout
                style={{ marginRight: 20 }}
                gap={{ rows: 0, cols: 10 }}
              >
                <GridLayoutItem
                  style={{
                    textAlign: "end",
                    marginTop: "20px",
                    width: "100%",
                  }}
                >
                  <Button
                    type="button"
                    fillMode={"outline"}
                    themeColor={"primary"}
                    style={{ marginRight: 4 }}
                    onClick={() => navigate("/usermanage")}
                  >
                    Cancel
                  </Button>
                  <ButtonWithLoading
                    label={user_guid ? "Update" : "Create"}
                    type="submit"
                    disabled={!formRenderProps.allowSubmit || loading}
                    loading={loading}
                  />
                </GridLayoutItem>
              </GridLayout>
            </ShadowCard>
          </FormElement>
        )}
      />
    </>
  );
};

export default CreateUserManagement;
