import { Typography } from "@progress/kendo-react-common";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import FormSelectionField from "../../../components/formFields/FormSelectionField";
import { requiredValidator } from "../../../components/formFields/CommonValidator";
import FormTextField from "../../../components/formFields/FormTextField";
import { useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import ButtonWithLoading from "../../../components/common/ButtonWithLoading";
import {
  deleteTourItinerary,
  getTourItinerariesByTourID,
  insertTourItinerary,
  updateTourItinerary,
} from "../services/tour.services";

import AppDialog, {
  DialogCloseButton,
} from "../../../components/dialog/Dialog";
import { Button } from "@progress/kendo-react-buttons";
import {
  Grid as KendoGrid,
  GridColumn as Column,
  GridCellProps,
  GridPageChangeEvent,
  GridCustomHeaderCellProps,
  GridCustomCellProps,
} from "@progress/kendo-react-grid";
import {
  HeaderThElement,
  PagerTargetEvent,
} from "@progress/kendo-react-data-tools";
import {
  closeDialog,
  openDialog,
} from "../../../components/dialog/dialogSlice";
import IconButton from "../../../components/common/IconButton";
import { FiEdit } from "react-icons/fi";
import { MdDelete } from "react-icons/md";
import AlertBox from "../../../components/common/AlertBox";
import { LoadingPanel } from "../../../components/layout/Loading";
import ShadowCard from "../../../components/common/ShadowCard";
import {
  clearTourItineraryDetails,
  clearTourItineraryGUID,
  clearTourItineraryList,
  decreaseActiveStep,
  setTourItineraryDetails,
  setTourItineraryGUID,
} from "../tourSlice";
import { IItinerary } from "../../itinerary/itineraryModel";
import { getAllActiveFacilitys } from "../../facility/services/facility.services";
import { IFacility } from "../../facility/facilityModel";
import FormMultiSelectionFiled from "../../../components/formFields/FormMultiSelectionFiled";
import FormTextArea from "../../../components/formFields/FormTextArea";
import { getItineraryBySectorID } from "../../itinerary/services/itinerary.services";

const TourItinerary = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const TourID = location.state?.TourID;
  const SectorID = location.state?.SectorID;

  console.log("Location:", location);

  useEffect(() => {
    if (TourID) {
      dispatch(getTourItinerariesByTourID(+TourID));
    }
    if (SectorID) {
      dispatch(getItineraryBySectorID(`${SectorID}`));
    }
  }, [SectorID, TourID]);

  useEffect(() => {
    dispatch(getAllActiveFacilitys());
    return () => {
      dispatch(clearTourItineraryDetails());
      dispatch(clearTourItineraryList());
    };
  }, []);

  return (
    <>
      <DeleteTourItineraryDialog />
      <TourItineraryForm />
      <TourItineraryGridComponent />
    </>
  );
};

const TourItineraryForm: React.FC = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const TourID = location.state?.TourID;
  const loading = useAppSelector((state) => state.tour.loading);
  const ItineraryList = useAppSelector(
    (state) => state.itinerary.ItineraryList
  );
  const FacilityList = useAppSelector((state) => state.facility.FacilityList);
  const TourItineraryDetails = useAppSelector(
    (state) => state.tour.TourItineraryDetails
  );
  const [formKey, setFormKey] = useState(1);

  useEffect(() => {
    setFormKey(formKey + 1);
  }, [TourItineraryDetails]);

  const handleSubmit = async (values: any) => {
    const formdata = {
      tour_id: +TourID,
      day: +values?.day,
      description: values?.description,
      itinerary_id: +values?.itinerary_id,
      facility_id: Array.isArray(values?.facility_id)
        ? values.facility_id.join(",")
        : values?.facility_id || "",
    };
    try {
      if (!TourItineraryDetails?.tour_itinerary_guid) {
        const response = await dispatch(insertTourItinerary(formdata));
        if (response?.meta?.requestStatus === "fulfilled") {
          dispatch(getTourItinerariesByTourID(TourID));
          setFormKey(formKey + 1);
          dispatch(clearTourItineraryDetails());
        }
      } else {
        const editPayload = {
          tour_itinerary_guid: values?.tour_itinerary_guid ?? null,
          ...formdata,
        };
        const response = await dispatch(updateTourItinerary(editPayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          dispatch(getTourItinerariesByTourID(TourID));
          setFormKey(formKey + 1);
          dispatch(clearTourItineraryDetails());
        }
      }
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      throw error;
    }
  };

  return (
    <Form
      onSubmit={handleSubmit}
      key={formKey}
      initialValues={TourItineraryDetails}
      render={(formRenderProps: FormRenderProps) => (
        <FormElement>
          <ShadowCard style={{ padding: 10 }}>
            <GridLayout
              style={{ marginRight: 30 }}
              gap={{ rows: 0, cols: 10 }}
              cols={[
                { width: "10%" },
                { width: "20%" },
                { width: "30%" },
                { width: "40%" },
              ]}
            >
              <GridLayoutItem colSpan={4}>
                <Typography.h4>{"Itinerary Details"}</Typography.h4>
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  name={`day`}
                  label="Day"
                  component={FormTextField}
                  validator={requiredValidator}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  name={`itinerary_id`}
                  label={"Itinerary Title"}
                  component={FormSelectionField}
                  // validator={requiredValidator}
                  options={ItineraryList?.map((itinerary: IItinerary) => {
                    return {
                      value: itinerary?.id,
                      label: itinerary?.itinerary_title,
                    };
                  })}
                />
              </GridLayoutItem>

              <GridLayoutItem>
                <Field
                  name={`facility_id`}
                  label={"Facility"}
                  component={FormMultiSelectionFiled}
                  validator={requiredValidator}
                  options={FacilityList?.map((facility: IFacility) => {
                    return {
                      value: facility?.id,
                      label: facility?.name,
                    };
                  })}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  name="description"
                  label="Description"
                  validator={requiredValidator}
                  component={FormTextArea}
                  rows={2}
                />
              </GridLayoutItem>
              <GridLayoutItem
                colSpan={4}
                style={{ textAlign: "end", marginTop: 20 }}
              >
                <Button
                  type="button"
                  fillMode="outline"
                  themeColor="primary"
                  style={{ marginRight: 10 }}
                  onClick={() => dispatch(decreaseActiveStep())}
                >
                  Previous
                </Button>
                <Button
                  fillMode="outline"
                  themeColor="primary"
                  style={{ marginRight: 10 }}
                  onClick={() => {
                    setFormKey(formKey + 1);
                    dispatch(clearTourItineraryDetails());
                  }}
                >
                  Clear
                </Button>
                <ButtonWithLoading
                  label={
                    TourItineraryDetails?.tour_itinerary_guid ? "Update" : "Add"
                  }
                  type="submit"
                  disabled={!formRenderProps.allowSubmit || loading}
                  loading={loading}
                />
                <Button
                  fillMode="solid"
                  themeColor="primary"
                  style={{ marginLeft: 10 }}
                  onClick={() => navigate("/tour")}
                >
                  Go to Tour List
                </Button>
              </GridLayoutItem>
            </GridLayout>
            {/* <DayChangeWatcher formRenderProps={formRenderProps} /> */}
          </ShadowCard>
        </FormElement>
      )}
    />
  );
};

interface PageState {
  skip: number;
  take: number;
}

const initialDataState: PageState = { skip: 0, take: 10 };

const TourItineraryGridComponent: React.FC = () => {
  const gridRef = useRef<any>(null);
  const dispatch = useAppDispatch();
  const TourItineraryList = useAppSelector(
    (state) => state.tour.TourItineraryList
  );
  const loading = useAppSelector((state) => state.tour.loading);

  const [page, setPage] = React.useState<PageState>(initialDataState);
  const [pageSizeValue, setPageSizeValue] = React.useState<
    number | string | undefined
  >();

  const pageChange = (event: GridPageChangeEvent) => {
    const targetEvent = event.targetEvent as PagerTargetEvent;
    const take =
      targetEvent.value === "All" ? TourItineraryList.length : event.page.take;

    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value);
    }
    setPage({
      ...event.page,
      take,
    });
  };

  const MyEditCommandCell = (props: GridCellProps) => {
    const handleEdit = (item: any) => {
      const payload = {
        tour_itinerary_guid: item?.tour_itinerary_guid,
        itinerary_id: item?.itinerary_id,
        tour_id: item?.tour_id,
        day: item?.day,
        description: item?.description,
        facility_id: item?.facility_id,
        isactive: item?.isactive,
      };
      dispatch(setTourItineraryDetails(payload));
    };
    const handleOpenDeleteDialog = (ID: string) => {
      dispatch(openDialog("deleteTourItinerary"));
      dispatch(setTourItineraryGUID(ID));
    };

    return (
      <td
        className={props.className}
        style={{
          ...props.style,
          textAlign: "center",
          display: "flex",
          justifyContent: "space-evenly",
        }}
      >
        <IconButton
          type="button"
          fillMode="flat"
          size="small"
          title="Edit"
          onClick={() => handleEdit(props.dataItem)}
        >
          <FiEdit className="absolute-position" style={{ fontSize: "20px" }} />
        </IconButton>
        <IconButton
          type="button"
          fillMode="flat"
          size="small"
          title="Delete"
          onClick={() =>
            handleOpenDeleteDialog(props.dataItem?.tour_itinerary_guid)
          }
        >
          <MdDelete
            className="absolute-position"
            style={{ fontSize: "24px" }}
          />
        </IconButton>
      </td>
    );
  };

  const HeaderCustomCell = (props: GridCustomHeaderCellProps) => (
    <HeaderThElement
      columnId={props.thProps?.columnId || ""}
      {...props.thProps}
      className={`table-header ${props.index === 14 ? "locked-header" : ""}`}
    >
      {props.children}
    </HeaderThElement>
  );

  const CustomCell = (props: any) => {
    return (
      <td {...props.tdProps} colSpan={1} className={"table-cell"}>
        {props.children}
      </td>
    );
  };

  const MyDataCustomCell = (props: GridCustomCellProps) => (
    <CustomCell {...props} />
  );

  if (loading) return <LoadingPanel gridRef={gridRef} />;
  return (
    <>
      {TourItineraryList && TourItineraryList.length ? (
        <KendoGrid
          style={{ marginTop: 20 }}
          // data={TourItineraryList.map((e: any) => {
          //   return {
          //     ...e,
          //     ItineraryTime: e.ItineraryTime
          //       ? moment(e.ItineraryTime, "YYYY-MM-DD").format("DD/MM/YYYY")
          //       : "",
          //   };
          // })}
          data={TourItineraryList}
          skip={page.skip}
          take={page.take}
          total={TourItineraryList.length}
          pageable={{
            buttonCount: 5,
            pageSizes: [5, 10, 15, "All"],
            pageSizeValue: pageSizeValue,
            type: "input",
          }}
          onPageChange={pageChange}
          cells={{
            headerCell: HeaderCustomCell,
            data: MyDataCustomCell,
          }}
        >
          <Column
            field="day"
            title="Day No."
            width={90}
            cell={(props) => <td>Day {props.dataItem.day}</td>}
          />
          <Column field="itinerary_name" title="Itinerary Title" width={300} />
          <Column field="description" title="Description" />
          <Column
            field="id"
            title="Actions"
            cell={MyEditCommandCell}
            width={"110px"}
            filterable={false}
            locked={true}
          />
        </KendoGrid>
      ) : (
        <AlertBox />
      )}
    </>
  );
};

const DeleteTourItineraryDialog: React.FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const TourID = location.state?.TourID;
  const TourItineraryGUID = useAppSelector(
    (state) => state.tour.TourItineraryGUID
  );
  const dialogName = useAppSelector((state) => state.dialog.dialogName);

  const handleDeleteAction = async (ID: string) => {
    if (ID) {
      const response = await dispatch(deleteTourItinerary(ID));
      if (response?.meta?.requestStatus === "fulfilled") {
        dispatch(getTourItinerariesByTourID(TourID));
        dispatch(closeDialog());
        dispatch(clearTourItineraryGUID());
      } else {
        dispatch(getTourItinerariesByTourID(TourID));
        dispatch(closeDialog());
        dispatch(clearTourItineraryGUID());
      }
    }
  };

  return (
    <>
      {dialogName === "deleteTourItinerary" && (
        <AppDialog>
          <>
            <Typography.h5>{"Delete Tour Itinerary"}</Typography.h5>
            <GridLayout>
              <GridLayoutItem>
                <Typography.p>
                  Are you sure you want to delete this Tour Itinerary?
                </Typography.p>
              </GridLayoutItem>
              <GridLayoutItem
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <DialogCloseButton themeColor="error" />
                <Button
                  fillMode={"solid"}
                  themeColor={"error"}
                  onClick={() => handleDeleteAction(TourItineraryGUID)}
                  type="button"
                >
                  Delete
                </Button>
              </GridLayoutItem>
            </GridLayout>
          </>
        </AppDialog>
      )}
    </>
  );
};

export default TourItinerary;
