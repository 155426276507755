import { Button } from "@progress/kendo-react-buttons";
import { Typography } from "@progress/kendo-react-common";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { getAllUsersM } from "./services/userManagement.services";
import { closeDialog, openDialog } from "../../components/dialog/dialogSlice";
// import { clearGhatReceiptDetail, setGhatReceiptID } from "./";
import NoImage from "../../assets/Images/useravatar.jpg";
import AppDialog, { DialogCloseButton } from "../../components/dialog/Dialog";
import {
  CompositeFilterDescriptor,
  filterBy,
} from "@progress/kendo-data-query";
import {
  GridCellProps,
  GridCustomCellProps,
  GridCustomHeaderCellProps,
  GridFilterChangeEvent,
  Grid as KendoGrid,
  GridColumn as Column,
  GridPageChangeEvent,
} from "@progress/kendo-react-grid";
import {
  HeaderThElement,
  // PagerTargetEvent,
} from "@progress/kendo-react-data-tools";
import IconButton from "../../components/common/IconButton";
import { FiEdit } from "react-icons/fi";
import { MdDelete } from "react-icons/md";
import { LoadingPanel } from "../../components/layout/Loading";
import AlertBox from "../../components/common/AlertBox";
import { setCurrentPage, setPageLimit } from "../pagination/paginationSlice";
import { setUserMID } from "./userManagementSlice";

const UserManagement: React.FC = () => {
  // const navigate = useNavigate();
  const dispatch = useAppDispatch();
  useEffect(() => {
    return () => {
      dispatch(setCurrentPage(0));
    };
  }, []);
  useEffect(() => {
    return () => {
      dispatch(closeDialog());
    };
  }, []);

  // const handleProcessCreate = () => {
  //   navigate("/usermanagement/create");
  // };

  return (
    <>
      <DeleteUserMDialog />
      <GridLayout cols={[{ width: "100%" }]}>
        <GridLayoutItem
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "4px 20px 20px 0",
          }}
        >
          <Typography.h4 style={{ marginBottom: 0, marginLeft: "3px" }}>
            Users List
          </Typography.h4>
          {/* <Button
            onClick={handleProcessCreate}
            fillMode={"solid"}
            themeColor={"primary"}
          >
            Create User
          </Button> */}
        </GridLayoutItem>
        <GridLayoutItem>
          <UserManageGridComponent />
        </GridLayoutItem>
      </GridLayout>
    </>
  );
};

const DeleteUserMDialog: React.FC = () => {
  const dispatch = useAppDispatch();
  const UserMID = useAppSelector((state) => state.userM.UserMID);
  const dialogName = useAppSelector((state) => state.dialog.dialogName);
  //   const currentPage = useAppSelector((state) => state.pagination.currentPage);
  //   const pageLimit = useAppSelector((state) => state.pagination.pageLimit);

  const handleDeleteAction = async (ID: number | null) => {
    if (ID) {
      //   const response = await dispatch(deleteUserM(ID));
      dispatch(setCurrentPage(0));
      //   const payload = {
      //     limit: +pageLimit,
      //     skip: +currentPage * +pageLimit,
      //   };
      //   if (response.payload?.Data.Status === 200) {
      //     dispatch(getAllUsersM(payload));
      //     dispatch(closeDialog());
      //   } else {
      //     dispatch(getAllUsersM(payload));
      //     dispatch(closeDialog());
      //   }
    }
  };

  return (
    <>
      {dialogName === "deleteUserM" && (
        <AppDialog>
          <>
            <Typography.h5>{"Delete User?"}</Typography.h5>
            <GridLayout>
              <GridLayoutItem>
                <Typography.p>
                  Are you sure you want to delete this User?
                </Typography.p>
              </GridLayoutItem>
              <GridLayoutItem
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <DialogCloseButton themeColor="error" />
                <Button
                  fillMode={"solid"}
                  themeColor={"error"}
                  onClick={() => {
                    handleDeleteAction(UserMID);
                  }}
                  type="button"
                >
                  Delete
                </Button>
              </GridLayoutItem>
            </GridLayout>
          </>
        </AppDialog>
      )}
    </>
  );
};

interface PageState {
  skip: number;
  take: number;
}

const initialDataState: PageState = { skip: 0, take: 10 };

const UserManageGridComponent: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const UserList = useAppSelector((state) => state.userM.UserMList);
  const currentPage = useAppSelector((state) => state.pagination.currentPage);
  const pageLimit = useAppSelector((state) => state.pagination.pageLimit);
  const [page, setPage] = React.useState<PageState>(initialDataState);
  const loading = useAppSelector((state) => state.userM.loading);
  const [filter, setFilter] = React.useState<
    CompositeFilterDescriptor | undefined
  >(undefined);
  const gridRef = useRef<any>(null);

  useEffect(() => {
    const payload = {
      limit: +pageLimit,
      skip: +currentPage * +pageLimit,
    };
    // console.log(payload);
    dispatch(getAllUsersM(payload));
  }, [currentPage, pageLimit]);

  const pageChange = (event: GridPageChangeEvent) => {
    const newPage = event.page.skip / event.page.take;
    dispatch(setPageLimit(event.page.take));
    dispatch(setCurrentPage(newPage));
  };

  const MyImageCommandCell = (props: GridCellProps) => {
    const Image = props.dataItem?.profile_photo;

    const handleImageClick = () => {
      if (Image && typeof Image === "string") {
        window.open(Image);
      }
    };

    if (!Image) {
      return (
        <td>
          <img
            src={NoImage}
            alt="No Profile"
            style={{ width: "50px", height: "50px" }}
          />
        </td>
      );
    }
    return (
      <td>
        <img
          src={Image}
          alt="Profile Photo"
          onClick={handleImageClick}
          style={{ width: "50px", height: "50px", objectFit: "cover" }}
        />
      </td>
    );
  };

  const MyEditCommandCell = (props: GridCellProps) => {
    const handleEditItem = (user_guid: number) => {
      navigate("/usermanage/edit", {
        state: { ID: props.dataItem?.Id, user_guid: user_guid },
      });
    };

    const handleOpenDeleteDialog = (ID: number) => {
      dispatch(openDialog("deleteUserM"));
      dispatch(setUserMID(ID));
    };

    return (
      <td className="action-td">
        <IconButton
          type="button"
          fillMode="flat"
          size="small"
          title="Edit"
          onClick={() => handleEditItem(props.dataItem?.user_guid)}
        >
          <FiEdit className="absolute-position" style={{ fontSize: "20px" }} />
        </IconButton>
        <IconButton
          type="button"
          fillMode="flat"
          size="small"
          title="Delete"
          onClick={() => handleOpenDeleteDialog(props.dataItem.user_guid)}
        >
          <MdDelete
            className="absolute-position"
            style={{ fontSize: "24px" }}
          />
        </IconButton>
      </td>
    );
  };
  const handleFilterChange = (e: GridFilterChangeEvent) => {
    setFilter(e.filter);
    setPage({ skip: 0, take: page.take });
  };

  const HeaderCustomCell = (props: GridCustomHeaderCellProps) => (
    <HeaderThElement
      columnId={props.thProps?.columnId || ""}
      {...props.thProps}
      className={`table-header ${props.index === 5 ? "locked-header" : ""}`}
    >
      {props.children}
    </HeaderThElement>
  );
  const CustomCell = (props: any) => {
    return (
      <td {...props.tdProps} colSpan={1} className={"table-cell"}>
        {props.children}
      </td>
    );
  };
  const MyDataCustomCell = (props: GridCustomCellProps) => (
    <CustomCell {...props} />
  );

  if (loading) return <LoadingPanel gridRef={gridRef} />;

  return (
    <>
      {UserList && UserList.length ? (
        <KendoGrid
          style={{ maxHeight: "calc(100vh - 152px)" }}
          filterable={true}
          filter={filter}
          data={filter ? filterBy(UserList, filter) : UserList}
          total={UserList[0]?.itemcount || UserList.length}
          onFilterChange={handleFilterChange}
          skip={currentPage * pageLimit}
          take={pageLimit}
          pageable={{
            buttonCount: 5,
            pageSizes: [10, 50, 100, 300, 500],
            pageSizeValue: pageLimit,
            type: "input",
          }}
          onPageChange={pageChange}
          cells={{
            headerCell: HeaderCustomCell,
            data: MyDataCustomCell,
          }}
        >
          <Column
            field="profile_photo"
            title="Profile"
            width={100}
            cell={MyImageCommandCell}
          />
          <Column
            field="user_guid"
            title="Fullname"
            cell={(props: any) => (
              <td>
                <div
                // className="hoverableName"
                // onClick={() =>
                //   navigate("/usermanage/edit", {
                //     state: {
                //       user_guid: props.dataItem?.user_guid,
                //     },
                //   })
                // }
                >
                  {props.dataItem?.first_name} {props.dataItem?.last_name}
                </div>
              </td>
            )}
          />
          <Column
            field="date_of_birth"
            title="Date Of Birth"
            // cell={(props) => (
            //   <td>
            //     {moment(props.dataItem?.date_of_birth, "DD/MM/YYYY").format(
            //       "D MMM YYYY"
            //     )}
            //   </td>
            // )}
          />
          <Column field="nationality_name" title="Nationality" />
          <Column field="city_name" title="City" />

          <Column
            field="user_guid"
            title="Actions"
            cell={MyEditCommandCell}
            width={"110px"}
            filterable={false}
            locked={true}
          />
        </KendoGrid>
      ) : (
        <AlertBox />
      )}
    </>
  );
};

export default UserManagement;
