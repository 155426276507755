import React, { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  filterBy,
  CompositeFilterDescriptor,
} from "@progress/kendo-data-query";
import {
  Grid as KendoGrid,
  GridColumn as Column,
  GridCellProps,
  GridFilterChangeEvent,
  GridPageChangeEvent,
  GridCustomHeaderCellProps,
  GridCustomCellProps,
} from "@progress/kendo-react-grid";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { PagerTargetEvent } from "@progress/kendo-react-data-tools";
import { HeaderThElement } from "@progress/kendo-react-data-tools";
import { Typography } from "@progress/kendo-react-common";
import { Button } from "@progress/kendo-react-buttons";
import { Switch } from "@progress/kendo-react-inputs";
import AlertBox from "../../components/common/AlertBox";
import { LoadingPanel } from "../../components/layout/Loading";
import IconButton from "../../components/common/IconButton";
import AppDialog, { DialogCloseButton } from "../../components/dialog/Dialog";
import { closeDialog, openDialog } from "../../components/dialog/dialogSlice";
import { MdDelete } from "react-icons/md";
import CreateItinerary from "./CreateItinerary ";
import {
  ActiveInactiveItinerary,
  deleteItinerary,
  getAllItinerary,
} from "./services/itinerary.services";
import { clearItineraryGUID, setItineraryGUID } from "./itinerarySlice";
// import { checkAcessRights } from "../../_helper/helper";
// import {
//   DELETE_ACCESS,
//   EDIT_ACCESS,
//   INSERT_ACCESS,
// } from "../../_contstants/common";
import ButtonCreate from "../../components/muidrawer/ButtonCreate";
import AppDrawer from "../../components/muidrawer/Drawer";
import ButtonEdit from "../../components/muidrawer/ButtonEdit";

const Itinerary: React.FC = () => {
  const dispatch = useAppDispatch();
  const isDrawerOpen = useAppSelector((state) => state.muidrawer.open);
  const drawerName = useAppSelector((state) => state.muidrawer.name);
  // const isAddAccess = checkAcessRights(location.pathname, INSERT_ACCESS);

  useEffect(() => {
    dispatch(getAllItinerary());
    return () => {
      dispatch(closeDialog());
    };
  }, []);

  return (
    <>
      <DeleteItineraryDialog />
      <GridLayout cols={[{ width: "100%" }]}>
        <GridLayoutItem
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "4px 20px 20px 0",
          }}
        >
          <Typography.h4 style={{ marginBottom: 0, marginLeft: "3px" }}>
            Itinerary List
          </Typography.h4>
          {/* {isAddAccess && ( */}
          <ButtonCreate label="Create Itinerary" drawerName="itineraryForm" />
          {/* )} */}
        </GridLayoutItem>
        <GridLayoutItem>
          <ItineraryGridComponent />
        </GridLayoutItem>
        {isDrawerOpen && drawerName === "itineraryForm" && (
          <AppDrawer>
            <CreateItinerary />
          </AppDrawer>
        )}
      </GridLayout>
    </>
  );
};

interface PageState {
  skip: number;
  take: number;
}

const initialDataState: PageState = { skip: 0, take: 10 };

const ItineraryGridComponent: React.FC = () => {
  const gridRef = useRef<any>(null);
  const dispatch = useAppDispatch();
  // const isEditAccess = checkAcessRights(location.pathname, EDIT_ACCESS);
  // const isDeleteAccess = checkAcessRights(location.pathname, DELETE_ACCESS);
  const ItineraryList = useAppSelector(
    (state) => state.itinerary.ItineraryList
  );
  const loading = useAppSelector((state) => state.itinerary.loading);

  const [filter, setFilter] = React.useState<
    CompositeFilterDescriptor | undefined
  >(undefined);
  const [page, setPage] = React.useState<PageState>(initialDataState);
  const [pageSizeValue, setPageSizeValue] = React.useState<
    number | string | undefined
  >();

  const pageChange = (event: GridPageChangeEvent) => {
    const targetEvent = event.targetEvent as PagerTargetEvent;
    const take =
      targetEvent.value === "All" ? ItineraryList.length : event.page.take;

    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value);
    }
    setPage({
      ...event.page,
      take,
    });
  };

  const MyEditCommandCell = (props: GridCellProps) => {
    const handleOpenDeleteDialog = (ID: string) => {
      dispatch(openDialog("deleteItinerary"));
      dispatch(setItineraryGUID(ID));
    };

    return (
      <td
        className="action-td"
        style={{
          ...props.style,
        }}
      >
        {/* {isEditAccess && ( */}
        <ButtonEdit
          ID={props.dataItem.itinerary_guid}
          name="itineraryForm"
          tooltipTitle="Edit"
        />
        {/* )} */}
        {/* {isDeleteAccess && ( */}
        <IconButton
          type="button"
          fillMode="flat"
          size="small"
          title="Delete"
          onClick={() => handleOpenDeleteDialog(props.dataItem.itinerary_guid)}
        >
          <MdDelete
            className="absolute-position"
            style={{ fontSize: "24px" }}
          />
        </IconButton>
        {/* )} */}
      </td>
    );
  };

  const handleFilterChange = (e: GridFilterChangeEvent) => {
    setFilter(e.filter);
    setPage({ skip: 0, take: page.take });
  };
  const HeaderCustomCell = (props: GridCustomHeaderCellProps) => (
    <HeaderThElement
      columnId={props.thProps?.columnId || ""}
      {...props.thProps}
      className="table-header"
    >
      {props.children}
    </HeaderThElement>
  );

  const CustomCell = (props: any) => {
    return (
      <td {...props.tdProps} colSpan={1} className={"table-cell"}>
        {props.children}
      </td>
    );
  };

  const MyDataCustomCell = (props: GridCustomCellProps) => (
    <CustomCell {...props} />
  );

  const MyActiveInActtiveCommandCell = (props: GridCellProps) => {
    const [IsActive, setIsActive] = useState<boolean>(props.dataItem.isactive);

    const handleActiveInActive = () => {
      setIsActive(!IsActive);
      const data = {
        itinerary_guid: props.dataItem.itinerary_guid,
        isactive: !IsActive,
      };
      dispatch(ActiveInactiveItinerary(data)).then((response: any) => {
        if (response.payload?.data?.Status === 200) {
          dispatch(getAllItinerary());
        } else {
          dispatch(getAllItinerary());
        }
      });
    };

    return (
      <td style={{ textAlign: "center" }}>
        <Switch
          //   disabled={isEditAccess ? false : true}
          name="isactive"
          checked={IsActive}
          onChange={handleActiveInActive}
          size="small"
        />
      </td>
    );
  };

  const MyTextEditorCell = (props: GridCustomCellProps) => {
    return (
      <td
        style={{ textAlign: "justify" }}
        dangerouslySetInnerHTML={{ __html: props?.dataItem?.description }}
      />
    );
  };

  if (loading) return <LoadingPanel gridRef={gridRef} />;
  return (
    <>
      {ItineraryList && ItineraryList.length ? (
        <KendoGrid
          style={{ maxHeight: "calc(100vh - 152px)" }}
          filterable={true}
          filter={filter}
          data={
            filter
              ? filterBy(ItineraryList, filter).slice(
                  page.skip,
                  page.take + page.skip
                )
              : ItineraryList.slice(page.skip, page.take + page.skip)
          }
          onFilterChange={handleFilterChange}
          skip={page.skip}
          take={page.take}
          total={ItineraryList.length}
          pageable={{
            buttonCount: 5,
            pageSizes: [5, 10, 15, "All"],
            pageSizeValue: pageSizeValue,
            type: "input",
          }}
          onPageChange={pageChange}
          cells={{
            headerCell: HeaderCustomCell,
            data: MyDataCustomCell,
          }}
        >
          <Column
            field="itinerary_title"
            title="Itinerary"
            cell={(props: any) => (
              <td
                className={"hoverableName"}
                // className={`${isEditAccess ? "hoverableName" : "normalName"}`}
              >
                {/* {isEditAccess ? ( */}
                <ButtonEdit
                  ID={props.dataItem.itinerary_guid}
                  name="itineraryForm"
                  label={props.dataItem?.itinerary_title}
                />
                {/* ) : (
                  props.dataItem?.itinerary_title
                )} */}
              </td>
            )}
          />
          <Column field="sector_name" title="Sector Name" />
          <Column
            field="description"
            title="Description"
            cell={MyTextEditorCell}
          />
          <Column
            field="id"
            title="Status"
            cell={MyActiveInActtiveCommandCell}
            width={"90px"}
            filterable={false}
            locked={true}
          />
          {/* {(isEditAccess || isDeleteAccess) && ( */}
          <Column
            field="id"
            title="Actions"
            cell={MyEditCommandCell}
            width={"110px"}
            filterable={false}
          />
          {/* )} */}
        </KendoGrid>
      ) : (
        <AlertBox />
      )}
    </>
  );
};

const DeleteItineraryDialog: React.FC = () => {
  const dispatch = useAppDispatch();
  const ItineraryGUID = useAppSelector(
    (state) => state.itinerary.ItineraryGUID
  );
  const dialogName = useAppSelector((state) => state.dialog.dialogName);
  const handleDeleteAction = async (ID: string) => {
    if (ID) {
      const response = await dispatch(deleteItinerary(ID));
      if (response.payload.Data.Status === 200) {
        dispatch(getAllItinerary());
        dispatch(closeDialog());
        dispatch(clearItineraryGUID());
      } else {
        dispatch(getAllItinerary());
        dispatch(closeDialog());
        dispatch(clearItineraryGUID());
      }
    }
  };

  return (
    <>
      {dialogName === "deleteItinerary" && (
        <AppDialog>
          <>
            <Typography.h5>{"Delete Itinerary"}</Typography.h5>
            <GridLayout>
              <GridLayoutItem>
                <Typography.p>
                  Are you sure you want to delete this Itinerary?
                </Typography.p>
              </GridLayoutItem>
              <GridLayoutItem
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <DialogCloseButton themeColor="error" />
                <Button
                  fillMode={"solid"}
                  themeColor={"error"}
                  onClick={() => handleDeleteAction(ItineraryGUID)}
                  type="button"
                >
                  Delete
                </Button>
              </GridLayoutItem>
            </GridLayout>
          </>
        </AppDialog>
      )}
    </>
  );
};

export default Itinerary;
