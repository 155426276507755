import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../../app/config";
import { axiosApiInstance } from "../../../app/axios";
import { ErrorToast, SuccessToast } from "../../../components/toast/Toasts";
import { IRoomType } from "../roomTypeModel";

export const getAllRoomTypes = createAsyncThunk(
  "RoomType/FindAllRoomType",
  async () => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/RoomType/FindAllRoomType`,
        {
          room_type: "",
        }
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching Room Types:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const createRoomType = createAsyncThunk(
  "RoomType/InsertRoomType",
  async (RoomTypeData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/RoomType/InsertRoomType`,
        RoomTypeData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error creating room type:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const updateRoomType = createAsyncThunk(
  "RoomType/UpdateRoomType",
  async (RoomTypeData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/RoomType/UpdateRoomType`,
        RoomTypeData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error updating room type:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const deleteRoomType = createAsyncThunk(
  "RoomType/DeleteRoomType",
  async (GUID: string) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/RoomType/DeleteRoomType`,
        { room_type_guid: GUID }
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error deleting room type:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getRoomTypeByID = createAsyncThunk(
  "RoomType/FindByIDRoomType",
  async (GUID: string) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/RoomType/FindByIDRoomType`,
        { room_type_guid: GUID }
      );
      const result = response.data?.Data as any;
      return {
        id: result.id,
        room_type_guid: result.room_type_guid,
        room_type: result.room_type,
        prefix: result.prefix,
        isactive: result.isactive ? 1 : 2,
      } as IRoomType;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error fetching room type:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const getAllActiveRoomTypes = createAsyncThunk(
  "RoomType/FindAllActiveRoomType",
  async () => {
    try {
      const response = await axiosApiInstance.get(
        `${API_URL}/RoomType/FindAllActiveRoomType`
      );
      return response.data?.Data;
    } catch (error: any) {
      // ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error Fetching room types:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);

export const ActiveInactiveRoomType = createAsyncThunk(
  "RoomType/ActiveInActiveRoomType",
  async (formData: any) => {
    try {
      const response = await axiosApiInstance.post(
        `${API_URL}/RoomType/ActiveInActiveRoomType`,
        formData
      );
      SuccessToast(response.data?.Details || "Success");
      return response.data;
    } catch (error: any) {
      ErrorToast(error?.response?.data?.Details || "Something went wrong");
      console.error("Error active-inactive room type:", error);
      return Promise.reject({
        message: error?.response?.data?.Details || "Something went wrong",
      });
    }
  }
);
