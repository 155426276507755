import React, { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import moment from "moment";
import { Typography } from "@progress/kendo-react-common";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { LoadingPanel } from "../../components/layout/Loading";
import { getAbandonedFlightBookingByBookingId } from "./services/abandonedFlightBooking.services";
const ViewAbandonedFlightBookig = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getAbandonedFlightBookingByBookingId(id || ""));
  }, []);
  //   const dispatch = useAppDispatch();
  const AbandonedFlightBookingDetails = useAppSelector(
    (state) => state.abandonedFlightBooking.AbandonedFlightBookingDetail
  );
  const loading = useAppSelector(
    (state) => state.abandonedFlightBooking.formLoading
  );
  const gridRef = useRef<any>(null);

  const card = {
    backgroundColor: "#fff",
    padding: "10px",
    marginBottom: "5px",
    borderRadius: "5px",
    boxshadow:
      "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px",
    // border: "1px dotted lightgrey",

    display: "flex",
    gap: "10px",
  };

  const flightCSS = {
    backgroundColor: "#fff",
    padding: "10px",
    boxshadow:
      "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px",
    borderRadius: "5px",
    marginBottom: "10px",
    marginRight: "10px",
    // border: "1px dotted lightgrey",
    gap: "10px 0",
  };
  const content = {
    margin: "0",
  };

  const leftContent = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: 0,
  };

  if (loading) return <LoadingPanel gridRef={gridRef} />;
  return (
    <GridLayout
      style={{
        marginRight: 40,
        padding: "0 50px",
        height: "100vh",
        // overflow: "hidden",
      }}
      gap={{ rows: 5, cols: 40 }}
      cols={[{ width: "70%" }, { width: "30%" }]}
    >
      {/* Flight Booking Header */}
      <GridLayoutItem
        colSpan={2}
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography.h4 style={{ margin: "0" }}>
          {"Flight Booking Details"}
        </Typography.h4>
      </GridLayoutItem>
      {/* Booking Number and Date */}

      <GridLayoutItem colSpan={2} style={{ position: "sticky" }}>
        <Typography.h5 style={{ margin: "0" }}>
          Booking No:{" "}
          <strong style={{ color: "green" }}>
            {AbandonedFlightBookingDetails?.booking_no}
          </strong>
        </Typography.h5>
      </GridLayoutItem>
      <GridLayoutItem colSpan={2}>
        <Typography.p style={{ margin: "0" }}>
          Booked on:{" "}
          <strong>
            {moment(AbandonedFlightBookingDetails?.booking_date).format(
              "DD-MMM-yyyy | h:mm:ss A"
            )}
          </strong>
        </Typography.p>
      </GridLayoutItem>

      {/* Flight Details Title*/}

      <GridLayoutItem>
        <Typography.h5
          style={{
            margin: "0",
            padding: "5px",
          }}
        >
          {"Flight Details"}
        </Typography.h5>
      </GridLayoutItem>

      {/* Fare Details Title */}

      <GridLayoutItem>
        <Typography.h5 style={{ margin: "0", padding: "5px" }}>
          {"Fare Details"}
        </Typography.h5>
      </GridLayoutItem>

      {/* Flight Details Mapping */}

      <GridLayoutItem
        rowSpan={2}
        style={{
          overflowY: "scroll",
          // padding: "0 0 90px 0",
          height: "65vh",
        }}
        className="viewBookingScroll"
      >
        {AbandonedFlightBookingDetails?.flight_details?.map(
          (f: any, index: number) => (
            <GridLayout
              style={flightCSS}
              key={index}
              gap={{ rows: 10 }}
              cols={[
                { width: "15%" },
                { width: "35%" },
                { width: "15%" },
                { width: "35%" },
              ]}
            >
              <GridLayoutItem>
                <Typography.p style={content}>AIRLINE</Typography.p>
                <Typography.h5 style={content}>{f?.airline_name}</Typography.h5>
              </GridLayoutItem>
              <GridLayoutItem>
                <Typography.p style={content}>DEPARTURE</Typography.p>
                <Typography.h5 style={content}>
                  {f?.departure_place}
                </Typography.h5>
                <Typography.p style={content}>
                  {f?.departure_airport}
                </Typography.p>
              </GridLayoutItem>
              <GridLayoutItem style={{ textAlign: "center" }}>
                {/* <Typography.p style={content}>STOP</Typography.p> */}
                <Typography.h5 style={content}>NON-STOP</Typography.h5>
              </GridLayoutItem>
              <GridLayoutItem style={{ textAlign: "end" }}>
                <Typography.p style={content}>ARRIVAL</Typography.p>
                <Typography.h5 style={content}>
                  {f?.arrival_place}
                </Typography.h5>
                <Typography.p style={content}>
                  {f?.arrival_airport}
                </Typography.p>
              </GridLayoutItem>
              <GridLayoutItem>
                <Typography.p style={content}>CLASS</Typography.p>
                <Typography.h5 style={content}>{f?.flight_class}</Typography.h5>
              </GridLayoutItem>
              <GridLayoutItem>
                <Typography.h5 style={content}>
                  {moment(f?.departure_date_time).format("DD-MMM-yyyy")}
                </Typography.h5>
                <Typography.h5 style={content}>
                  {moment(f?.departure_date_time).format("HH:mm")}
                </Typography.h5>
              </GridLayoutItem>
              <GridLayoutItem style={{ textAlign: "center" }}>
                <Typography.p style={content}>DURATION</Typography.p>
                <Typography.h5 style={content}>{f?.duration}</Typography.h5>
              </GridLayoutItem>
              <GridLayoutItem style={{ textAlign: "end" }}>
                <Typography.h5 style={content}>
                  {moment(f?.arrival_date_time).format("DD-MMM-yyyy")}
                </Typography.h5>
                <Typography.h5 style={content}>
                  {moment(f?.arrival_date_time).format("HH:mm")}
                </Typography.h5>
              </GridLayoutItem>
            </GridLayout>
          )
        )}
      </GridLayoutItem>

      {/* Fare Summary */}
      <GridLayoutItem>
        <div style={{ ...card, flexDirection: "column" }}>
          <Typography.p style={leftContent}>
            Base Fair
            <span style={{ margin: 0, fontWeight: "bold" }}>
              {AbandonedFlightBookingDetails?.base_fare ? "₹" : "-"}{" "}
              {AbandonedFlightBookingDetails?.base_fare}
            </span>
          </Typography.p>

          <Typography.p style={leftContent}>
            Taxes & Fees{" "}
            <span style={{ margin: 0, fontWeight: "bold" }}>
              {AbandonedFlightBookingDetails?.total_airline_tax ? "₹" : "-"}
              {AbandonedFlightBookingDetails?.total_airline_tax}
            </span>
          </Typography.p>
          <Typography.p
            style={{
              ...leftContent,
              borderTop: "1px dotted lightgrey",
            }}
          >
            Grand Total
            <span style={{ margin: 0, fontWeight: "bold" }}>
              {AbandonedFlightBookingDetails?.amount ? "₹" : "-"}
              {AbandonedFlightBookingDetails?.amount}
            </span>
          </Typography.p>
        </div>

        {/* Contact and Passenger Details */}

        <Typography.h5 style={{ margin: "0", padding: "5px" }}>
          {"Contact Details"}
        </Typography.h5>
        <div style={{ ...card, flexDirection: "column" }}>
          <Typography.p style={leftContent}>
            Email{" "}
            <strong>
              {AbandonedFlightBookingDetails?.email_id ? "₹" : "-"}
              {AbandonedFlightBookingDetails?.email_id}
            </strong>
          </Typography.p>
          <Typography.p style={leftContent}>
            Mobile
            <strong>
              {AbandonedFlightBookingDetails?.mobile_no ? "₹" : "-"}
              {AbandonedFlightBookingDetails?.mobile_no}
            </strong>
          </Typography.p>
        </div>
        <Typography.h5 style={{ margin: "0", padding: "5px" }}>
          {`Passenger Details (${AbandonedFlightBookingDetails?.passenger_details.length} )`}
        </Typography.h5>
      </GridLayoutItem>
      <GridLayoutItem
        style={{
          overflowY: "scroll",
          minHeight: "45vh",
        }}
        className="viewBookingScroll"
      >
        {AbandonedFlightBookingDetails?.passenger_details?.map(
          (p: any, index: number) => (
            <div
              key={index}
              style={{
                ...card,
                flexDirection: "column",
                padding: "2px 10px",
              }}
            >
              <Typography.p style={leftContent}>
                <strong>{`${p.initial} ${p.first_name} ${p.last_name} - (${p.gender})`}</strong>
                <strong> {`${p.pax_type}`}</strong>
              </Typography.p>
            </div>
          )
        )}
      </GridLayoutItem>
    </GridLayout>
  );
};

export default ViewAbandonedFlightBookig;
