import React from "react";
import "./loginnew.scss";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import FormTextField from "../../components/formFields/FormTextField";
import {
  passwordValidator,
  requiredValidator,
} from "../../components/formFields/CommonValidator";
import LOGO from "../../assets/Images/logobs.png";
import { Link, Navigate, useNavigate } from "react-router-dom";
import ButtonWithLoading from "../../components/common/ButtonWithLoading";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { employeeLogin } from "./loginSlice";
import SideImage from "../../assets/Images/loginsideimage.svg";
import FormPasswordField from "../../components/formFields/FormPasswordField";

const LoginNew: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const loading = useAppSelector((state) => state.login.loading);
  const isAuthenticated = localStorage.getItem("Token");
  if (isAuthenticated) {
    return <Navigate to="/" replace />;
  }
  // const OTPData = useAppSelector((state) => state.login.otpData);
  // const resend = useAppSelector((state) => state.login.resend);

  // const [timer, setTimer] = useState(30);
  // const [isTimerActive, setIsTimerActive] = useState(false);

  // useEffect(() => {
  //   let interval: NodeJS.Timeout | null = null;
  //   if (isTimerActive) {
  //     interval = setInterval(() => {
  //       setTimer((prev) => prev - 1);
  //     }, 1000);
  //   }
  //   if (timer === 0) {
  //     setIsTimerActive(false);
  //     setTimer(30);
  //     if (interval) {
  //       clearInterval(interval);
  //     }
  //   }
  //   return () => {
  //     if (interval) {
  //       clearInterval(interval);
  //     }
  //   };
  // }, [isTimerActive, timer]);

  const handleSubmit = async (values: any) => {
    // if (!OTPData || resend) {
    //   dispatch(setResend(false));
    //   dispatch(generateOTP(mobile_no));
    //   setIsTimerActive(true);
    //   return;
    // }
    const data = {
      employee_name: values.employee_name,
      password: values.password,
    };
    const response = await dispatch(employeeLogin(data));
    if (response?.meta?.requestStatus === "fulfilled") {
      navigate("/");
    }
  };

  return (
    <div className="outer-container">
      <div className="inner-container">
        <div className="logo">
          <img src={LOGO} alt="logo" height={80} />
        </div>
        <div className="form-login">
          {/* LEFT */}
          <div className="left-content">
            <div className="image-container">
              {/* <img src="./sideImage.svg" alt="sideImage" /> */}
              <img src={SideImage} alt="sideImage" />
            </div>
            <h1>Login to Big Smile Admin Portal.</h1>
          </div>
          {/* RIGHT */}
          <div className="right-form">
            <h2>Log In</h2>
            <Form
              onSubmit={handleSubmit}
              initialValues={{
                username: "",
                password: "",
              }}
              render={(formRenderProps: FormRenderProps) => (
                <FormElement className="loginform">
                  <Field
                    name={"employee_name"}
                    placeholder="Username"
                    autocomplete={false}
                    type={"text"}
                    component={FormTextField}
                    validator={requiredValidator}
                  />
                  <Field
                    name={"password"}
                    type={"password"}
                    component={FormPasswordField}
                    label={"Password"}
                    validator={passwordValidator}
                  />
                  {/* {OTPData && (
                    <Field
                      name={"otp"}
                      type={"number"}
                      placeholder="OTP"
                      component={FormTextField}
                    />
                  )}
                  {OTPData && (
                    <Button
                      className="resend-btn"
                      type="submit"
                      disabled={loading || isTimerActive}
                      onClick={() => dispatch(setResend(true))}
                    >
                      {isTimerActive ? `Resend OTP in ${timer}s` : "Resend OTP"}
                    </Button>
                  )} */}

                  <ButtonWithLoading
                    label={"Login"}
                    themeColor="primary"
                    type="submit"
                    width="100%"
                    disabled={!formRenderProps.allowSubmit || loading}
                    loading={loading}
                    className="login-btn "
                  />
                  {/* <ButtonWithLoading
                    label={OTPData ? "Login" : "Generate OTP"}
                    themeColor="primary"
                    type="submit"
                    width="100%"
                    disabled={!formRenderProps.allowSubmit || loading}
                    loading={loading}
                    className="login-btn"
                  /> */}
                  <hr />
                  <div className="register">
                    <p>
                      Not registered yet? <Link to="/signup">Register Now</Link>
                    </p>
                  </div>
                </FormElement>
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginNew;
