import React, { useEffect, useRef } from "react";
import { Typography } from "@progress/kendo-react-common";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { findAllActiveAbandonedFlightBooking } from "./services/abandonedFlightBooking.services";
import { closeDialog } from "../../components/dialog/dialogSlice";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import {
  CompositeFilterDescriptor,
  filterBy,
} from "@progress/kendo-data-query";
import {
  // GridCellProps,
  GridCustomCellProps,
  GridCustomHeaderCellProps,
  GridFilterChangeEvent,
  GridPageChangeEvent,
  Grid as KendoGrid,
  GridColumn as Column,
} from "@progress/kendo-react-grid";
import { HeaderThElement } from "@progress/kendo-react-data-tools";
import { LoadingPanel } from "../../components/layout/Loading";
import AlertBox from "../../components/common/AlertBox";
import { setCurrentPage, setPageLimit } from "../pagination/paginationSlice";
import { Link } from "react-router-dom";
import ButtonForAll from "../../components/common/Button";
import { useResponsiveJSX } from "../../_helper/useResponsiveJSX";

const AbandonedFlightBooking: React.FC = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      dispatch(closeDialog());
    };
  }, []);
  return (
    <>
      <GridLayout cols={[{ width: "100%" }]}>
        <GridLayoutItem
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "4px 20px 20px 0",
          }}
        >
          <Typography.h4 style={{ marginBottom: 0, marginLeft: "3px" }}>
            Aabandoned Flight Booking List
          </Typography.h4>
        </GridLayoutItem>
        <GridLayoutItem>
          <AbandonedFlightBookingsGridComponent />
        </GridLayoutItem>
      </GridLayout>
    </>
  );
};
interface PageState {
  skip: number;
  take: number;
}
const initialDataState: PageState = { skip: 0, take: 10 };
const AbandonedFlightBookingsGridComponent: React.FC = () => {
  const gridRef = useRef<any>(null);
  const dispatch = useAppDispatch();
  const AbandonedFlightBookingList = useAppSelector(
    (state) => state.abandonedFlightBooking.AbandonedFlightBookingList
  );
  const loading = useAppSelector(
    (state) => state.abandonedFlightBooking.loading
  );
  const currentPage = useAppSelector((state) => state.pagination.currentPage);
  const pageLimit = useAppSelector((state) => state.pagination.pageLimit);
  const [filter, setFilter] = React.useState<
    CompositeFilterDescriptor | undefined
  >(undefined);
  const [page, setPage] = React.useState<PageState>(initialDataState);
  const bp = useResponsiveJSX([1024, 2170, 2514]);
  const widthsize = [150, 200, 250, 300];
  const dynamicWidth = widthsize[bp] ?? 300;

  useEffect(() => {
    const payload = {
      limit: +pageLimit,
      skip: +currentPage * +pageLimit,
    };
    // console.log(payload);
    dispatch(findAllActiveAbandonedFlightBooking(payload));
  }, [currentPage, pageLimit]);

  const pageChange = (event: GridPageChangeEvent) => {
    const newPage = event.page.skip / event.page.take;
    dispatch(setPageLimit(event.page.take));
    dispatch(setCurrentPage(newPage));
  };

  if (loading) return <LoadingPanel gridRef={gridRef} />;

  const MyEditCommandCell = (props: any) => {
    const id = props.dataItem?.booking_no;
    return (
      <td
        className={`action-td ${props.className}`}
        style={{
          ...props.style,
        }}
      >
        <Link to={`viewbooking/${id}`} type="button" target="_blank">
          <ButtonForAll fillMode="solid" label="View" themeColor="primary" />
        </Link>
      </td>
    );
  };

  const handleFilterChange = (e: GridFilterChangeEvent) => {
    setFilter(e.filter);
    setPage({ skip: 0, take: page.take });
  };
  const HeaderCustomCell = (props: GridCustomHeaderCellProps) => (
    <HeaderThElement
      columnId={props.thProps?.columnId || ""}
      {...props.thProps}
      className={`table-header ${props.index === 9 ? "locked-header" : ""}`}
    >
      {props.children}
    </HeaderThElement>
  );

  const CustomCell = (props: any) => {
    return (
      <td {...props.tdProps} colSpan={1} className={"table-cell"}>
        {props.children}
      </td>
    );
  };

  const MyDataCustomCell = (props: GridCustomCellProps) => (
    <CustomCell {...props} />
  );

  return (
    <>
      {AbandonedFlightBookingList && AbandonedFlightBookingList.length > 0 ? (
        <KendoGrid
          style={{ maxHeight: "calc(100vh - 152px)" }}
          filterable={true}
          filter={filter}
          data={
            filter
              ? filterBy(AbandonedFlightBookingList, filter)
              : AbandonedFlightBookingList
          }
          total={
            AbandonedFlightBookingList[0]?.itemcount ||
            AbandonedFlightBookingList.length
          }
          onFilterChange={handleFilterChange}
          skip={currentPage * pageLimit}
          take={pageLimit}
          pageable={{
            buttonCount: 5,
            pageSizes: [10, 50, 100, 300, 500],
            pageSizeValue: pageLimit,
            type: "input",
          }}
          onPageChange={pageChange}
          cells={{
            headerCell: HeaderCustomCell,
            data: MyDataCustomCell,
          }}
          resizable={true}
        >
          <Column
            width={dynamicWidth}
            field="booking_no"
            title="Booking No"
            cell={(props: any) => (
              <td className="hoverableName">
                <Link
                  className="hoverableName"
                  to={`viewbooking/${props.dataItem?.booking_no}`}
                  type="button"
                  target="_blank"
                >
                  {props.dataItem?.booking_no}
                </Link>
              </td>
            )}
          />
          <Column
            field="booking_date"
            title="Booking Date"
            width={dynamicWidth}
          />
          <Column field="from_place" title="From Place" width={dynamicWidth} />
          <Column field="to_place" title="To Place" width={dynamicWidth} />
          <Column
            field="from_date_time"
            title="From Date "
            width={dynamicWidth}
          />
          <Column field="to_date_time" title="To Date " width={dynamicWidth} />
          <Column
            field="amount"
            width={dynamicWidth}
            title="Amount"
            cell={(props: any) => (
              <td style={{ fontWeight: "bold" }}>₹{props?.dataItem?.amount}</td>
            )}
          />
          <Column
            field="book_by_name"
            title="Book By"
            width={bp === 2 ? 250 : 300}
          />
          <Column
            field="booking_status"
            width={180}
            title="Booking Status"
            cell={(props: any) => {
              return (
                <td style={{ textAlign: "center" }}>
                  {props?.dataItem?.booking_status === "Pending" ? (
                    <>
                      <Typography.p
                        style={{
                          color: "#965E00",
                          fontWeight: "bold",
                          padding: "2px 10px",
                          width: "fit-content",
                          backgroundColor: "#FFECCC",
                          borderRadius: "10px",
                          margin: 0,
                        }}
                      >
                        Pending
                      </Typography.p>
                    </>
                  ) : props?.dataItem?.booking_status === "Cancelled" ? (
                    <>
                      <Typography.p
                        style={{
                          color: "#6E6893",
                          fontWeight: "bold",
                          padding: "2px 10px",
                          width: "fit-content",
                          backgroundColor: "#F4F2FF",
                          borderRadius: "10px",
                          margin: 0,
                        }}
                      >
                        Cancelled
                      </Typography.p>
                    </>
                  ) : (
                    <>
                      <Typography.p
                        style={{
                          color: "#D30000",
                          fontWeight: "bold",
                          padding: "2px 10px",
                          width: "fit-content",
                          backgroundColor: "#FFE0E0",
                          borderRadius: "10px",
                          margin: 0,
                        }}
                      >
                        Failed
                      </Typography.p>
                    </>
                  )}
                </td>
              );
            }}
          />
          <Column
            field="flight_booking_guid"
            title="Actions"
            locked
            cell={MyEditCommandCell}
            width={120}
            filterable={false}
          />
        </KendoGrid>
      ) : (
        <AlertBox />
      )}
    </>
  );
};

export default AbandonedFlightBooking;
