import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  deleteTravallerInfo,
  getAllUsersM,
  getUserMById,
  updateUserM,
} from "./services/userManagement.services";
import { IUserM, IUserMState } from "./userManagementModel";

const initialState: IUserMState = {
  loading: false,
  formloading: false,
  error: "",
  UserMList: [],
  UserMID: null,
  TravelerInfoDeleteIndex: -1,
  UserMDetails: {
    Id: null,
    user_guid: "",
    first_name: "",
    last_name: "",
    date_of_birth: "",
    gender: null,
    nationality_id: null,
    nationality_name: "",
    city_id: null,
    city_name: "",
    profile_photo: "",
    is_email_verified: false,
    billing_address: "",
    billing_state_id: null,
    billing_state_name: "",
    billing_city_id: null,
    billing_city_name: "",
    billing_pan_card_no: null,
    billing_pincode: "",
    billing_gst_no: "",
    travellers_information: [],
    isactive: 1,
  },
};

const userMSlice = createSlice({
  name: "userM",
  initialState,
  reducers: {
    clearUserMDetails: (state) => {
      state.UserMDetails = initialState.UserMDetails;
    },
    setTravelerInfoDeleteIndex: (state, action) => {
      state.TravelerInfoDeleteIndex = action.payload;
    },
    setUserMID: (state, action) => {
      state.UserMID = action.payload;
    },
    clearUserMID: (state) => {
      state.UserMID = initialState.UserMID;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllUsersM.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      getAllUsersM.fulfilled,
      (state, action: PayloadAction<IUserM[]>) => {
        state.loading = false;
        state.UserMList = action.payload || [];
        state.error = "";
      }
    );
    builder.addCase(getAllUsersM.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.UserMList = [];
    });
    /* FindByIdUserTravellerBilling */
    builder.addCase(getUserMById.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getUserMById.fulfilled, (state, action) => {
      state.loading = false;
      state.UserMDetails = action.payload;
      state.error = "";
    });
    builder.addCase(getUserMById.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
      state.UserMDetails = initialState.UserMDetails;
    });
    builder.addCase(updateUserM.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateUserM.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(updateUserM.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
    builder.addCase(deleteTravallerInfo.pending, (state) => {
      state.formloading = true;
    });
    builder.addCase(deleteTravallerInfo.fulfilled, (state) => {
      state.formloading = false;
    });
    builder.addCase(deleteTravallerInfo.rejected, (state, action) => {
      state.formloading = false;
      state.error = action.error.message || "Something went wrong";
    });
  },
});
export const {
  setUserMID,
  clearUserMID,
  clearUserMDetails,
  setTravelerInfoDeleteIndex,
} = userMSlice.actions;
export default userMSlice.reducer;
