import { Typography } from "@progress/kendo-react-common";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import FormSelectionField from "../../../components/formFields/FormSelectionField";
import { requiredValidator } from "../../../components/formFields/CommonValidator";
import FormTextField from "../../../components/formFields/FormTextField";
import { useLocation } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import ButtonWithLoading from "../../../components/common/ButtonWithLoading";
import {
  deleteTourRate,
  getTourRatesByTourID,
  insertTourRate,
  updateTourRate,
} from "../services/tour.services";
import moment from "moment";
import {
  clearTourCostID,
  clearTourCostDetails,
  setTourCostDetails,
  increaseActiveStep,
  decreaseActiveStep,
  setTourCostGUID,
} from "../tourSlice";
import FormDatePicker from "../../../components/formFields/FormDateField";
import { getAllActiveRoomTypes } from "../../roomtype/services/roomType.services";

import AppDialog, {
  DialogCloseButton,
} from "../../../components/dialog/Dialog";
import { Button } from "@progress/kendo-react-buttons";
import {
  Grid as KendoGrid,
  GridColumn as Column,
  GridCellProps,
  GridPageChangeEvent,
  GridCustomHeaderCellProps,
  GridCustomCellProps,
} from "@progress/kendo-react-grid";
import {
  HeaderThElement,
  PagerTargetEvent,
} from "@progress/kendo-react-data-tools";
import {
  closeDialog,
  openDialog,
} from "../../../components/dialog/dialogSlice";
import IconButton from "../../../components/common/IconButton";
import { FiEdit } from "react-icons/fi";
import { MdDelete } from "react-icons/md";
import AlertBox from "../../../components/common/AlertBox";
import { LoadingPanel } from "../../../components/layout/Loading";
import ShadowCard from "../../../components/common/ShadowCard";

const TourRates: React.FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const TourID = location.state?.TourID;

  useEffect(() => {
    dispatch(getTourRatesByTourID(+TourID));
    dispatch(getAllActiveRoomTypes());
    return () => {
      dispatch(clearTourCostDetails());
    };
  }, []);

  return (
    <>
      <DeleteTourRateDialog />
      <TourRateForm />
      <TourRateGridComponent />
    </>
  );
};

const TourRateForm: React.FC = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const TourID = location.state?.TourID;
  const loading = useAppSelector((state) => state.tour.loading);
  // const RoomTypeList = useAppSelector((state) => state.roomType.RoomTypeList);
  const TourCostDetails = useAppSelector((state) => state.tour.TourCostDetails);
  const [formKey, setFormKey] = useState(1);
  useEffect(() => {
    setFormKey(formKey + 1);
  }, [TourCostDetails]);

  const TOUR_RATE_TYPES = [
    { value: "SINGLE", label: "Single" },
    { value: "COUPLE", label: "Couple" },
  ];

  const handleSubmit = async (values: any) => {
    const formdata = {
      tour_id: TourID,
      from_date: values?.from_date,
      to_date: values?.to_date,
      rate_type: values?.rate_type,
      twin_sharing_rate: values?.twin_sharing_rate,
      three_sharing_rate: values?.three_sharing_rate,
      four_sharing_rate: values?.four_sharing_rate,
      cwb_rate: values?.cwb_rate,
      cnb_rate: values?.cnb_rate,
      infant_rate: values?.infant_rate,
    };
    try {
      if (!TourCostDetails?.tour_cost_guid) {
        const response = await dispatch(insertTourRate(formdata));
        if (response?.meta?.requestStatus === "fulfilled") {
          dispatch(getTourRatesByTourID(TourID));
          setFormKey(formKey + 1);
          dispatch(clearTourCostDetails());
        }
      } else {
        const editPayload = {
          tour_cost_guid: values?.tour_cost_guid,
          ...formdata,
        };
        const response = await dispatch(updateTourRate(editPayload));
        if (response?.meta?.requestStatus === "fulfilled") {
          dispatch(getTourRatesByTourID(TourID));
          setFormKey(formKey + 1);
          dispatch(clearTourCostDetails());
        }
      }
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      throw error;
    }
  };

  return (
    <Form
      onSubmit={handleSubmit}
      key={formKey}
      initialValues={TourCostDetails}
      render={(formRenderProps: FormRenderProps) => (
        <FormElement>
          <ShadowCard style={{ padding: 10 }}>
            <GridLayout
              style={{ marginRight: 40 }}
              gap={{ rows: 0, cols: 10 }}
              cols={[
                { width: "20%" },
                { width: "20%" },
                { width: "20%" },
                { width: "20%" },
                { width: "20%" },
              ]}
            >
              <GridLayoutItem colSpan={5}>
                <Typography.h4>{"Package Rate"}</Typography.h4>
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  name={`from_date`}
                  label="From Date"
                  format="dd/MM/yyyy"
                  minDate={moment().toDate()}
                  component={FormDatePicker}
                  validator={requiredValidator}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  name={`to_date`}
                  label="To Date"
                  format="dd/MM/yyyy"
                  minDate={moment(
                    formRenderProps.valueGetter("from_date")
                  ).toDate()}
                  component={FormDatePicker}
                  validator={requiredValidator}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  name={`rate_type`}
                  label={"Rate Type"}
                  component={FormSelectionField}
                  validator={requiredValidator}
                  options={TOUR_RATE_TYPES.map((ratetype: any) => {
                    return {
                      value: ratetype.value,
                      label: ratetype.label,
                    };
                  })}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  wrapperClassName="w-100"
                  name="twin_sharing_rate"
                  label="Twin Sharing Rate"
                  type="number"
                  component={FormTextField}
                  validator={requiredValidator}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  wrapperClassName="w-100"
                  name="three_sharing_rate"
                  label="Three Sharing Rate"
                  type="number"
                  component={FormTextField}
                  validator={requiredValidator}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  wrapperClassName="w-100"
                  name="four_sharing_rate"
                  label="Four Sharing Rate"
                  type="number"
                  component={FormTextField}
                  validator={requiredValidator}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  wrapperClassName="w-100"
                  name="cwb_rate"
                  label="CWB Rate"
                  type="number"
                  component={FormTextField}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  wrapperClassName="w-100"
                  name="cnb_rate"
                  label="CNB Rate"
                  type="number"
                  component={FormTextField}
                />
              </GridLayoutItem>
              <GridLayoutItem>
                <Field
                  name="infant_rate"
                  label="Infant Rate"
                  type="number"
                  component={FormTextField}
                />
              </GridLayoutItem>
              <GridLayoutItem
                colSpan={5}
                style={{ textAlign: "end", marginTop: 20 }}
              >
                <Button
                  type="button"
                  fillMode="outline"
                  themeColor="primary"
                  style={{ marginRight: 10 }}
                  onClick={() => dispatch(decreaseActiveStep())}
                >
                  Previous
                </Button>
                <Button
                  fillMode="outline"
                  themeColor="primary"
                  style={{ marginRight: 10 }}
                  onClick={() => {
                    setFormKey(formKey + 1);
                    dispatch(clearTourCostDetails());
                  }}
                >
                  Cancel
                </Button>
                <ButtonWithLoading
                  label={TourCostDetails?.tour_id ? "Update" : "Add"}
                  type="submit"
                  disabled={!formRenderProps.allowSubmit || loading}
                  loading={loading}
                />
                <Button
                  fillMode="solid"
                  themeColor="primary"
                  style={{ marginLeft: 10 }}
                  onClick={() => dispatch(increaseActiveStep())}
                >
                  Next
                </Button>
              </GridLayoutItem>
            </GridLayout>
          </ShadowCard>
        </FormElement>
      )}
    />
  );
};

interface PageState {
  skip: number;
  take: number;
}

const initialDataState: PageState = { skip: 0, take: 10 };

const TourRateGridComponent: React.FC = () => {
  const gridRef = useRef<any>(null);
  const dispatch = useAppDispatch();
  const TourCostList = useAppSelector((state) => state.tour.TourCostList);
  const loading = useAppSelector((state) => state.tour.loading);

  const [page, setPage] = React.useState<PageState>(initialDataState);
  const [pageSizeValue, setPageSizeValue] = React.useState<
    number | string | undefined
  >();

  const pageChange = (event: GridPageChangeEvent) => {
    const targetEvent = event.targetEvent as PagerTargetEvent;
    const take =
      targetEvent.value === "All" ? TourCostList.length : event.page.take;

    if (targetEvent.value) {
      setPageSizeValue(targetEvent.value);
    }
    setPage({
      ...event.page,
      take,
    });
  };

  const MyEditCommandCell = (props: GridCellProps) => {
    const handleEdit = (item: any) => {
      const payload = {
        tour_cost_guid: item.tour_cost_guid,
        tour_id: item.tour_id,
        from_date: item.from_date
          ? moment(item.from_date, "YYYY-MM-DD").toDate()
          : "",
        to_date: item.to_date
          ? moment(item.to_date, "YYYY-MM-DD").toDate()
          : "",
        rate_type: item.rate_type,
        twin_sharing_rate: item.twin_sharing_rate,
        three_sharing_rate: item.three_sharing_rate,
        four_sharing_rate: item.four_sharing_rate,
        cwb_rate: item.cwb_rate || 0,
        cnb_rate: item.cnb_rate || 0,
        infant_rate: item.infant_rate || 0,
      };
      dispatch(setTourCostDetails(payload));
    };
    const handleOpenDeleteDialog = (guid: string) => {
      dispatch(openDialog("deleteTourRate"));
      dispatch(setTourCostGUID(guid));
    };

    return (
      <td
        className={props.className}
        style={{
          ...props.style,
          textAlign: "center",
          display: "flex",
          justifyContent: "space-evenly",
        }}
      >
        <IconButton
          type="button"
          fillMode="flat"
          size="small"
          title="Edit"
          onClick={() => handleEdit(props.dataItem)}
        >
          <FiEdit className="absolute-position" style={{ fontSize: "20px" }} />
        </IconButton>
        <IconButton
          type="button"
          fillMode="flat"
          size="small"
          title="Delete"
          onClick={() => handleOpenDeleteDialog(props.dataItem.tour_cost_guid)}
        >
          <MdDelete
            className="absolute-position"
            style={{ fontSize: "24px" }}
          />
        </IconButton>
      </td>
    );
  };

  const HeaderCustomCell = (props: GridCustomHeaderCellProps) => (
    <HeaderThElement
      columnId={props.thProps?.columnId || ""}
      {...props.thProps}
      className={`table-header ${props.index === 14 ? "locked-header" : ""}`}
    >
      {props.children}
    </HeaderThElement>
  );

  const CustomCell = (props: any) => {
    return (
      <td {...props.tdProps} colSpan={1} className={"table-cell"}>
        {props.children}
      </td>
    );
  };

  const MyDataCustomCell = (props: GridCustomCellProps) => (
    <CustomCell {...props} />
  );

  if (loading) return <LoadingPanel gridRef={gridRef} />;
  return (
    <>
      {TourCostList && TourCostList.length ? (
        <KendoGrid
          style={{ marginTop: 20 }}
          data={TourCostList.map((e: any) => {
            return {
              ...e,
              StartDate: e.StartDate
                ? moment(e.StartDate, "YYYY-MM-DD").format("DD/MM/YYYY")
                : "",
              EndDate: e.EndDate
                ? moment(e.EndDate, "YYYY-MM-DD").format("DD/MM/YYYY")
                : "",
            };
          })}
          skip={page.skip}
          take={page.take}
          total={TourCostList.length}
          pageable={{
            buttonCount: 5,
            pageSizes: [5, 10, 15, "All"],
            pageSizeValue: pageSizeValue,
            type: "input",
          }}
          onPageChange={pageChange}
          cells={{
            headerCell: HeaderCustomCell,
            data: MyDataCustomCell,
          }}
        >
          <Column field="from_date" title="From Date" />
          <Column field="to_date" title="To Date" />
          <Column field="rate_type" title="Rate Type" />
          <Column field="twin_sharing_rate" title="Twin Sharing Rate" />
          <Column field="three_sharing_rate" title="Three Sharing Rate" />
          <Column field="four_sharing_rate" title="Four Sharing Rate" />
          <Column field="cwb_rate" title="CWB Rate" />
          <Column field="cnb_rate" title="CNB Rate" />
          <Column field="infant_rate" title="Infant Rate" />
          <Column
            field="id"
            title="Actions"
            cell={MyEditCommandCell}
            width={"110px"}
            filterable={false}
            locked={true}
          />
        </KendoGrid>
      ) : (
        <AlertBox />
      )}
    </>
  );
};

const DeleteTourRateDialog: React.FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const TourID = location.state?.TourID;
  const TourCostGUID = useAppSelector((state) => state.tour.TourCostGUID);
  const dialogName = useAppSelector((state) => state.dialog.dialogName);

  const handleDeleteAction = async (guid: string) => {
    if (guid) {
      const response = await dispatch(deleteTourRate(guid));
      if (response?.meta?.requestStatus === "fulfilled") {
        dispatch(getTourRatesByTourID(TourID));
        dispatch(closeDialog());
        dispatch(clearTourCostID());
      } else {
        dispatch(getTourRatesByTourID(TourID));
        dispatch(closeDialog());
        dispatch(clearTourCostID());
      }
    }
  };

  return (
    <>
      {dialogName === "deleteTourRate" && (
        <AppDialog>
          <>
            <Typography.h5>{"Delete Tour Rate"}</Typography.h5>
            <GridLayout>
              <GridLayoutItem>
                <Typography.p>
                  Are you sure you want to delete this Pakage Rate?
                </Typography.p>
              </GridLayoutItem>
              <GridLayoutItem
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <DialogCloseButton themeColor="error" />
                <Button
                  fillMode={"solid"}
                  themeColor={"error"}
                  onClick={() => handleDeleteAction(TourCostGUID)}
                  type="button"
                >
                  Delete
                </Button>
              </GridLayoutItem>
            </GridLayout>
          </>
        </AppDialog>
      )}
    </>
  );
};

export default TourRates;
